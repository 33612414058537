import format from 'date-fns/format';

const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {
    month: format(new Date(), 'MM'),
    year: format(new Date(), 'yyyy'),
  },
};

export default initialState;
