import { combineReducers } from 'redux';

import polygons from './polygons';
import totals from './totals';
import polygon from './polygon';

const reducer = combineReducers({
  [polygons.name]: polygons.reducer,
  [totals.name]: totals.reducer,
  [polygon.name]: polygon.reducer,
});

export default reducer;
