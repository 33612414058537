const ROUTES = {
  ROOT: '/profile',
  CREATE_COMPANY: '/profile/new-company',
  CREATE_PROFILE: '/profile/new-profile',
  EDIT_COMPANY: (id = '') =>
    id ? `/profile/edit-company/${id}` : '/profile/edit-company/:id',
  EDIT_USER: (id = '') =>
    id ? `/profile/edit-user/${id}` : '/profile/edit-user/:id',
  EDIT_PROFILE: (id = '') =>
    id ? `/profile/edit-profile/${id}` : '/profile/edit-profile/:id',
};

const PROFILE_ROUTES = {
  ROOT: ROUTES.ROOT,
  EDIT_USER: ROUTES.EDIT_USER,
  CREATE_COMPANY: ROUTES.CREATE_COMPANY,
  CREATE_PROFILE: ROUTES.CREATE_PROFILE,
  EDIT_COMPANY: ROUTES.EDIT_COMPANY,
  EDIT_PROFILE: ROUTES.EDIT_PROFILE,
};

export const NAMESPACE = ROUTES.ROOT;

export default PROFILE_ROUTES;
