const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  filters: {
    month: null,
    limit: null,
  },
};

export default initialState;
