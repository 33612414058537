import { useMemo } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import ROUTES from '@spot-spotview/constants/routes.constant';

import rootReducer from './root.reducer';
import rootSubscribe from './root.subscriber';
import rootSaga from './root.saga';

import { deleteAuthCookie } from '@spot/shared-utils/authCookie.util';

const userInvalid = (store) => (next) => (action: any) => {
  const { statusCode } = action?.payload || {};

  const isInvalidUser = statusCode === 401;
  if (isInvalidUser) {
    deleteAuthCookie();

    return window.location.reload();
  }

  const result = next(action);

  return result;
};

const initSaga = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
    LogRocket.reduxMiddleware(),
    userInvalid,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export function useStore() {
  const currentStore = useMemo(() => initSaga(), []);

  currentStore.subscribe(rootSubscribe(currentStore));

  return currentStore;
}
