import reducer from './reports.reducer';
import * as selector from './reports.selector';
import * as constant from './reports.constant';
import * as action from './reports.action';
import subscriber from './reports.subscriber';
import saga from './reports.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
