import { parse, isValid, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const toDateExtenseUtil = (date = new Date()) => {
  const date2 = parse(date.toString(), 'yyyy-MM-dd', new Date(), {
    locale: ptBR,
  });

  if (isValid(date2))
    return format(new Date(date2), "d 'de' MMMM 'de' yyyy", { locale: ptBR });
  else return format(new Date(date), "d 'de' MMMM 'de' yyyy", { locale: ptBR });
};

export default toDateExtenseUtil;
