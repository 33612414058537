import * as React from 'react';
import flow from 'lodash/flow';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';

import DialogBaseComponent from '@spot-spotview/components/DialogBase.component';

import ThemeProvider from '@spot-spotview/providers/Theme.provider';

import dialogDeleteProfile from '../store/dialogs/dialogDeleteProfile';
import profiles from '../store/profiles';

export default function DialogDeleteProfileComponent() {
  const dispatch = useDispatch();

  const open = useSelector(dialogDeleteProfile.selector.selectVisible);
  const payload = useSelector(dialogDeleteProfile.selector.selectPayload);
  const loading = useSelector(profiles.selector.selectLoading);

  const handleClose = flow(dialogDeleteProfile.action.close, dispatch);
  const profileDelete = flow(
    dialogDeleteProfile.action.profileDelete,
    dispatch
  );

  return (
    <ThemeProvider>
      <DialogBaseComponent
        boxProps={{ width: 450 }}
        visible={open}
        handleClose={handleClose}
        actions={
          <Box display="flex" gap={4}>
            <Button size="large" onClick={handleClose} sx={{ flex: 1 }}>
              Cancelar
            </Button>
            <Button
              size="large"
              onClick={() => {
                profileDelete();
              }}
              loading={loading}
              disabled={loading}
              variant="contained"
              color="error"
              sx={{ color: 'white', flex: 1 }}
            >
              Excluir
            </Button>
          </Box>
        }
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box>
              <img
                src="/img/atencao_excluir.png"
                style={{ display: 'block' }}
              />
            </Box>
            <Typography variant="h3" color="secondary.main">
              Deseja mesmo excluir o perfil {payload?.description}?
            </Typography>
            <Typography variant="subtitle1" color="gray.500">
              Ao excluir, essa ação não poderá ser desfeita.
            </Typography>
          </Box>
        </Box>
      </DialogBaseComponent>
    </ThemeProvider>
  );
}
