import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiOperationsPlanService() {
  const url = 'operations/plan';

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
  });
}
