import reducer from './polygon.reducer';
import * as constant from './polygon.constant';
import * as action from './polygon.action';
import * as selector from './polygon.selector';
import saga from './polygon.saga';
import initialState from './polygon.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
