import { ACTION_TYPES } from './profiles.constant';

export const serviceGet = (payload: { filters: any }) => ({
  type: ACTION_TYPES.SERVICE.GET,
  filters: payload.filters,
});

export const serviceGetId = (payload: { id: string }) => ({
  type: ACTION_TYPES.SERVICE.GET_ID,
  payload,
});

export const servicePatch = (payload) => ({
  type: ACTION_TYPES.SERVICE.PATCH,
  payload,
});

export const serviceDelete = (payload: { id: string }) => ({
  type: ACTION_TYPES.SERVICE.DELETE,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const servicePost = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST,
  payload,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const updateFilters = (filters) => ({
  type: ACTION_TYPES.UPDATE.FILTERS,
  filters,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET.FILTERS,
});
