import React, { useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ROUTES from './constants/blockchainRoutes.constant';

import BlockchainScreen from './screens/Blockchain.screen';

import useProtectedByAuth from '@spot-spotview/hooks/useProtectedByAuth.hook';

const ProfileModule = () => {
  useProtectedByAuth();

  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={BlockchainScreen} />
      </Switch>
    </Fragment>
  );
};

export default ProfileModule;
