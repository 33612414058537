import React from 'react';
import Box from '@mui/material/Box';

import ThemeProvider from '@spot-spotview/providers/Theme.provider';

const PrintLayout = ({ children }) => {
  return (
    <ThemeProvider>
      <Box
        bgcolor="gray.100"
        minHeight="100vh"
        pt={10}
        pb={10}
        sx={{
          '@media': {
            print: {
              bgcolor: 'blue',
            },
            page: {
              size: 'auto',
              margin: '0mm',
            },
          },
        }}
      >
        <Box
          maxWidth="210mm"
          margin="0 auto"
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PrintLayout;
