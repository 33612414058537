const OPERATIONS_ROUTES = {
  ROOT: '/operations',
  ID: (id: string | undefined = undefined) =>
    ['/operations', id ? `/${id}` : '/:id'].join(''),
  ID_PRINT: (id: string | undefined = undefined) =>
    ['/operations', id ? `/${id}` : '/:id', '/print'].join(''),
};

export const NAMESPACE = OPERATIONS_ROUTES.ROOT;

export default OPERATIONS_ROUTES;
