import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const FormObserverComponent = ({ onChange }) => {
  const formik = useFormikContext();
  const { values } = formik;

  useEffect(() => {
    if (onChange) {
      onChange(values, formik);
    }
  }, [values]);

  return null;
};

export default FormObserverComponent;
