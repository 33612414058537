import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import flow from 'lodash/flow';

import SearchIcon from '@mui/icons-material/Search';

import AppLayout from '@spot-spotview/layouts/App.layout';

import BoxSummary from '@spot-spotview/components/Box/BoxSummary.component';
import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import TableComponent from '@spot-spotview/components/Table.component';

import ROUTES from '@spot-spotview/constants/routes.constant';

import MapComponent from '../components/Map.component';

import polygons from '../store/polygons';
import totals from '../store/totals';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Canvas from '../components/MiniCanvas';

const PolygonsScreen = () => {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataPolygons: useSelector(polygons.selector.selectData),
    dataPolygonsMetadata: useSelector(polygons.selector.selectDataMetadata),
    dataPolygonsTotal: useSelector(totals.selector.selectData),
  };

  const dispatchRedux = {
    serviceGetAll: flow(polygons.action.serviceGet, dispatch),
    serviceGetTotals: flow(totals.action.serviceGetTotals, dispatch),
    serviceExport: flow(polygons.action.serviceExport, dispatch),
  };

  useEffect(() => {
    dispatchRedux.serviceGetTotals({ filters: {} });
    dispatchRedux.serviceGetAll({ filters: {} });
  }, []);

  useEffect(() => {
    const filters: any = {};
    const parsedText = parseInt(searchText);
    if (parsedText) filters.operation_code = parsedText;
    dispatchRedux.serviceGetAll({
      filters,
    });
  }, [searchText]);

  const columns = [
    {
      label: '',
      name: 'polygon',
      render: ({ polygon }) => (
        <Canvas polygon={polygon} width={129} height={56} />
      ),
    },
    {
      label: 'Operação',
      name: 'code',
    },
    {
      label: 'Criado',
      name: 'created',
    },
    {
      label: 'Área',
      name: 'area',
    },
    {
      name: 'action',
      render: (row) => (
        <Button
          onClick={() => history.push(ROUTES.POLYGONS.ID(row.id))}
          sx={{ color: 'info.main' }}
        >
          Ver mais
        </Button>
      ),
    },
  ];

  const map = (
    <Box>
      <Box p={'0px 32px 32px 32px'} position="relative" zIndex="1">
        <Box height="430px">
          <MapComponent />
        </Box>
      </Box>
      <BoxWhite
        mt={-35}
        height="320px"
        display="flex"
        justifyContent="center"
        alignItems="end"
      >
        <Box display="flex" gap={1} alignItems="center">
          <img src="/img/starPolygon.png" />
          <Typography align="center" variant="subtitle2" color="gray.400">
            <strong>Dica:</strong> Passe o mouse por cima do polígono para mais
            informações. Clique no polígono para ver detalhes do satélite e
            meteorologia.
          </Typography>
        </Box>
      </BoxWhite>
    </Box>
  );

  const summary = (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
      <BoxSummary
        label="Total de Polígonos"
        amount={selectorRedux.dataPolygonsTotal?.total_polygons}
        img="/img/poligonos.png"
      />
      <BoxSummary
        label="Área total"
        amount={selectorRedux.dataPolygonsTotal?.total_area}
        img="/img/mapa.png"
      />
    </Box>
  );

  const table = (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            label="Buscar por operação"
            size="small"
            onChange={(el) => setSearchText(el.target.value)}
            InputProps={{
              endAdornment: <SearchIcon sx={{ color: 'gray.300' }} />,
            }}
          />
          <Button
            size="large"
            sx={{ bgcolor: 'info.main', color: 'white' }}
            variant="contained"
            onClick={() => dispatchRedux.serviceExport({} as any)}
          >
            Download de todos polígonos
          </Button>
        </Box>

        <TableComponent
          component={false}
          columns={columns}
          name="alerts"
          data={selectorRedux.dataPolygons?.results}
          metadata={selectorRedux.dataPolygons?.metadata?.resultset}
          handleUpdateFilters={({ offset }) =>
            dispatchRedux.serviceGetAll({ filters: { offset } })
          }
        />
      </Box>
    </BoxWhite>
  );

  return (
    <AppLayout title="Polígonos">
      <Box display="flex" flexDirection="column" gap={3}>
        {map}
        {summary}
        {table}
      </Box>
    </AppLayout>
  );
};

export default PolygonsScreen;
