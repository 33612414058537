import reducer from './blockchain.reducer';
import * as constant from './blockchain.constant';
import * as action from './blockchain.action';
import * as selector from './blockchain.selector';
import saga from './blockchain.saga';
import initialState from './blockchain.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
