import { all } from 'redux-saga/effects';

import operations from './blockchain';

function* watch() {
  yield all([operations.saga.watch()]);
}

export default {
  watch,
};
