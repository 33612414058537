import { all } from 'redux-saga/effects';

import drawers from './dialogs';
import user from './currentUser';
import clients from './clients';
import invoices from './invoices';
import plan from './plan';
import datasource from './datasource';
import accounts from './accounts';
import companies from './companies';
import profiles from './profiles';
import cooperatives from './cooperatives';
import authenticate from './authenticate';

function* watch() {
  yield all([
    datasource.saga.watch(),
    profiles.saga.watch(),
    companies.saga.watch(),
    cooperatives.saga.watch(),
    plan.saga.watch(),
    invoices.saga.watch(),
    clients.saga.watch(),
    user.saga.watch(),
    drawers.saga.watch(),
    accounts.saga.watch(),
    authenticate.saga.watch(),
  ]);
}

export default {
  watch,
};
