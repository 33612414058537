import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  uf: string;
  city: string;
  culture_name: string;
  cycle: string;
  alert_type: string;
};

export default async function getApiDashboardsSummaryService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters, { skipNulls: true });

  const url = ['dashboards/summary', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'DASHBOARD',
    auth: true,
  });
}
