import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function deleteApiProfilesIdService(id: string) {
  const url = ['profiles', `${id}`].join('/');

  return fetch(url, {
    method: 'DELETE',
    ms: 'ACCOUNT',
    auth: true,
  });
}
