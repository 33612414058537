import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import AppLayout from '@spot-spotview/layouts/App.layout';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import FormObserverComponent from '@spot-spotview/components/FormObserver.component';

import FieldUploadPhotoComponent from '@spot-spotview/components/FieldUpload.component';

import blockchain from '../store/blockchain';

const BlockchainScreen = () => {
  const dispatch = useDispatch();

  const selectorRedux = {
    loadingBlockchain: useSelector(blockchain.selector.selectLoading),
    dataBlockchain: useSelector(blockchain.selector.selectData),
  };

  const dispatchRedux = {
    serviceGet: flow(blockchain.action.serviceGet, dispatch),
    resetState: flow(blockchain.action.resetState, dispatch),
  };

  const onDropFile = (values, formik) => {
    if (values?.file?.file) {
      dispatchRedux.serviceGet(values?.file?.file);

      formik.resetForm();
    }
  };

  useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const initial = (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography align="center" variant="h3">
          Verificador de Integridade
        </Typography>
        <Typography align="center" variant="subtitle1">
          Se você registrou ou recebeu um arquivo registrado no Notalhub,
          selecione-o para verificar se é uma cópia autêntica.
        </Typography>
      </Box>

      <Box width={500} alignSelf="center">
        <FieldUploadPhotoComponent name="file" accept={'*'} />
      </Box>
    </Box>
  );

  const resultElm = (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={700} variant="subtitle2">
          Nome do arquivo
        </Typography>
        <Typography>{selectorRedux.dataBlockchain?.payload?.name}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={700} variant="subtitle2">
          Impressão SHA-256
        </Typography>
        <Typography>{selectorRedux.dataBlockchain?.hash}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={700} variant="subtitle2">
          Resultado da Busca
        </Typography>
        <Typography>
          {Object.keys(selectorRedux.dataBlockchain?.result || {}).length ===
            0 && 'Não encontrado'}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          dispatchRedux.resetState();
        }}
      >
        Fazer upload de outro documento
      </Button>
    </Box>
  );

  const loadingElm = (
    <Box
      height={300}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );

  return (
    <AppLayout title="Blockchain">
      <Box display="flex" flexDirection="column" gap={3}>
        <BoxWhite>
          <Formik
            initialValues={{
              file: null,
            }}
            onSubmit={() => {}}
          >
            {() => (
              <Form>
                <FormObserverComponent onChange={onDropFile} />

                <Box display="flex" flexDirection="column" gap={4}>
                  {selectorRedux.loadingBlockchain === null && initial}

                  {selectorRedux.loadingBlockchain === false && resultElm}

                  {selectorRedux.loadingBlockchain === true && loadingElm}
                </Box>
              </Form>
            )}
          </Formik>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default BlockchainScreen;
