import { flow } from 'lodash';

import { NAME } from './dialogInviteAccount.constant';

import { selectState as selectStateDrawer } from '../dialogs.selector';

const selectStateKey = (state) => state[NAME];
const selectVisibleKey = (state) => state.visible;
const selectPayloadKey = (state) => state.payload;
const selectLoadingKey = (state) => state.loading;

export const selectState = flow(selectStateDrawer, selectStateKey);
export const selectVisible = flow(selectState, selectVisibleKey);
export const selectPayload = flow(selectState, selectPayloadKey);
export const selectLoading = flow(selectState, selectLoadingKey);
