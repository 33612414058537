import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  operation_code: string;
};

export default async function getApiPolygonsAllService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters, { skipNulls: true });

  const url = ['polygons', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'POLYGONS',
    auth: true,
  });
}
