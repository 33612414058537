import React, { useEffect, useRef } from 'react';
import { Box, Divider, Typography, Button, Switch } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import * as yup from 'yup';
import orderBy from 'lodash/orderBy';
import { v4 } from 'uuid';

import viaCepService from '@spot/shared-services/viaCep.service';
import ibgeService from '@spot/shared-services/ibge.service';

import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';

import FormArrComponent from '@spot-spotview/components/FormArrFormik.component';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';

import AppLayout from '@spot-spotview/layouts/App.layout';

import clients from '../store/clients';

import companies from '../store/companies';
import datasource from '../store/datasource';
import plan from '../store/plan';

import statesConstant from '../constants/state.constant';
import messagesSchemaConstant from '@spot/shared-constants/messagesSchema.constant';

const ProfileCreateEditCompantScreen = () => {
  const loaded = useRef(false);

  const dispatch = useDispatch();
  const params: any = useParams();

  const history = useHistory();

  const selectorRedux = {
    data: useSelector(companies.selector.selectData),
    dataDatasource: useSelector(datasource.selector.selectData),
    loadingDatasource: useSelector(datasource.selector.selectLoading),
  };

  const dispatchRedux = {
    servicePost: flow(companies.action.servicePost, dispatch),
    servicePatch: flow(companies.action.servicePatch, dispatch),
    serviceGetId: flow(companies.action.serviceGetId, dispatch),
    resetState: flow(companies.action.resetState, dispatch),
    serviceGetDatasource: flow(datasource.action.serviceGet, dispatch),
    serviceGetPlan: flow(plan.action.serviceGet, dispatch),
  };

  const isLoading = [selectorRedux.loadingDatasource].some((l) => l);

  const isId = params?.id;

  useEffectOnce(() => {
    if (isId) {
      dispatchRedux.serviceGetId(isId);
    }

    dispatchRedux.serviceGetDatasource();
    dispatchRedux.serviceGetPlan();
  });

  useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const title = isId ? selectorRedux.data?.name : 'Nova empresa';

  const up = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            onClick={() => {
              dispatchRedux.resetState();

              history.goBack();
            }}
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'info.main' }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const body = (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      validateOnChange={false}
      initialValues={Object.assign(
        {},
        {
          trading_name: '',
          company_name: '',
          billing_day: '',
          address_zip_code: '',
          address_street: '',
          address_number: '',
          address_complement: '',
          address_neighborhood: '',
          address_city: '',
          address_state: '',
          address_receipt_path: '',
          phone_prefix: '',
          phone_number: '',
          api_key: '',
          type: '',
          sponsor: '',
          sponsor_email: '',
          sponsor_phone: '',
          sponsor_ramal: '',
        },
        isId && { ...selectorRedux.data }
      )}
      validationSchema={yup.object({
        name: yup.string().required('Nome da empresa é obrigatório'),
        document_number: yup
          .string()
          .required('CNPJ é obrigatório')
          .min(14, messagesSchemaConstant.MIN),
        zip_code: yup
          .string()
          .min(8, messagesSchemaConstant.MIN)
          .required('CEP é obrigatório'),
        city: yup.string().required('Cidade é obrigatório'),
        neighborhood: yup.string().required('Bairro é obrigatório'),
        number: yup.string().required('Número é obrigatório'),
        state: yup.string().required('Selecione um Estado'),
        street: yup.string().required('Endereço é obrigatório'),
        sponsor: yup.string().required('Responsável é obrigatório'),
        sponsor_email: yup
          .string()
          .required('E-mail do responsável da empresa é obrigatório'),
        cli_plan_id: yup.string().required('Selecione algum plano'),
      })}
      onSubmit={async (values) => {
        if (isId) {
          dispatchRedux.servicePatch(values);
        } else {
          dispatchRedux.servicePost(values);
        }

        return true;
      }}
    >
      {({ values, errors, setFieldValue }) => (
        <Box display="flex" flexDirection="column" gap={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="18px" fontWeight={700} color="secondary.main">
              {title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Switch
                checked={values?.active}
                onChange={(ev, checked) => {
                  setFieldValue('active', checked);
                }}
              />
              <Typography fontSize="14px" fontWeight={400} color="primary.main">
                Ativa
              </Typography>
            </Box>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr"
            gap={3}
            alignItems="start"
          >
            <Form>
              <Box display="flex" flexDirection="column" gap={3}>
                <FormArrComponent
                  boxProps={{
                    gap: 4,
                  }}
                  fields={[
                    [
                      {
                        label: 'Nome comercial',
                        name: 'trading_name',
                      },
                      {
                        label: 'Nome da empresa',
                        name: 'company_name',
                      },
                      {
                        label: 'CNPJ',
                        name: 'document_number',
                        variant: 'cnpj',
                      },
                    ],
                    [
                      {
                        label: 'Chave API',
                        name: 'api_key',
                      },
                      {
                        label: 'Fonte de dados',
                        name: 'datasource_id',
                        options: [
                          {},
                          ...orderBy(selectorRedux.dataDatasource, 'ds_name'),
                        ].map((c) => ({
                          label: c.ds_name,
                          value: c.id,
                        })),
                        textFieldProps: {
                          select: true,
                          SelectProps: {
                            native: true,
                          },
                        },
                      },
                    ],
                  ]}
                />

                <Typography variant="subtitle2" color="gray.300">
                  Contato
                </Typography>

                <FormArrComponent
                  boxProps={{
                    gap: 4,
                  }}
                  fields={[
                    [
                      {
                        label: 'Responsável',
                        name: 'sponsor',
                      },
                      {
                        label: 'Email',
                        name: 'sponsor_email',
                      },
                      {
                        label: 'Telefone',
                        name: 'sponsor_phone',
                      },
                      {
                        label: 'Ramal',
                        name: 'sponsor_ramal',
                      },
                    ],
                  ]}
                />

                <Typography variant="subtitle2" color="gray.300">
                  Endereço
                </Typography>

                <FormArrComponent
                  boxProps={{
                    gap: 4,
                  }}
                  fields={[
                    [
                      {
                        label: 'CEP',
                        name: 'address_zip_code',
                        variant: 'zipcode',
                        textFieldProps: {
                          onBlur: async (value) => {
                            const lenghtValue = String(
                              String(value).replace(/\D/g, '')
                            ).length;

                            if (lenghtValue < 7) {
                              return null;
                            }

                            let [ok, result] = await viaCepService(value);

                            setFieldValue('address_street', result?.logradouro);
                            setFieldValue('address_state', result?.uf);
                            setFieldValue('address_city', result?.localidade);
                            setFieldValue(
                              'address_neighborhood',
                              result?.bairro
                            );

                            [ok, result] = await ibgeService(result?.ibge);

                            const resultObj = result?.[0] || {};

                            setFieldValue(
                              'lat',
                              resultObj?.centroide?.latitude
                            );
                            setFieldValue(
                              'lon',
                              resultObj?.centroide?.longitude
                            );
                          },
                        },
                      },
                      {
                        label: 'Rua',
                        name: 'address_street',
                      },
                      {
                        label: 'Número',
                        name: 'address_number',
                      },
                    ],
                    [
                      {
                        label: 'Complemento',
                        name: 'address_complement',
                      },
                      {
                        label: 'Estado',
                        name: 'address_state',
                        options: [{}, ...statesConstant].map((s: any) => ({
                          label: s?.nome,
                          value: s?.uf,
                        })),
                        textFieldProps: {
                          select: true,
                          SelectProps: {
                            native: true,
                          },
                        },
                      },
                      {
                        label: 'Cidade',
                        name: 'address_city',
                      },
                    ],
                    [
                      {
                        label: 'Bairro',
                        name: 'address_neighborhood',
                      },
                    ],
                  ]}
                />

                <Box alignSelf="end" display="flex" gap={4}>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  );

  return (
    <AppLayout
      title={false}
      breadcrumb={['Configurações', isId ? title : 'Nova empresa']}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <BoxWhite loading={isLoading}>
          <Box display="flex" flexDirection="column" gap={3}>
            {up}
            {body}
          </Box>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default ProfileCreateEditCompantScreen;
