import { createReducer } from '@reduxjs/toolkit';

import initialState from './ndvi.initialState';

import { ACTION_TYPES } from './ndvi.constant';

const handleReset = () => initialState;

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.data = action.payload;
  state.error = [false, null];
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
  state.data = initialState.data;
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
});
