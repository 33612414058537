export const NAME = 'authenticate';

const CONTEXT = 'PROFILE::AUTHENTICATE';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_ERROR = `${CONTEXT}::RESET::ERROR`;

const ERROR = `${CONTEXT}::ERROR`;

const SERVICE = {
  POST: SERVICE_POST,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const RESET = {
  STATE: RESET_STATE,
  ERROR: RESET_ERROR,
};

export const ACTION_TYPES = {
  FETCH,
  RESET,
  ERROR,
  SERVICE,
};
