import React, { PureComponent } from 'react';
import { useTheme } from '@mui/styles';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import misc from '@spot-spotview/store/configs/misc';
import { useSelector } from 'react-redux';
import toDecimal from '@spot-spotview/utils/toDecimal.util';

const data = [
  {
    name: 'Milho',
    Valor: 4000,
  },
  {
    name: 'Soja',
    Valor: 3000,
  },
  {
    name: 'Algodão',
    Valor: 2000,
  },
  {
    name: 'Feijão',
    Valor: 2780,
  },
];

export default function ChartBarComponent({ data: dataProp }) {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;

  const dataParsed = dataProp?.map((d) => ({
    name: d?.culture_name,
    area: Number(d?.sum_area),
  }));

  const theme: any = useTheme();

  const COLORS = [
    '#64dd17',
    '#ffd54f',
    '#00b8d4',
    '#f44336',
    '#7c4dff',
    '#009688',
    '#75a7ff',
    '#ff8a65',
    '#4db6ac',
    '#90a4ae',
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={dataParsed}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 16 }} interval={0} />
        <YAxis />
        <Tooltip />
        <Bar dataKey="area" fill="#82ca9d">
          {dataParsed?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
