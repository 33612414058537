import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import REPORTS_ROUTES from './constants/reportsRoutes.constant';

import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';

import * as dAction from './store/reports.action';

import useProtectedByAuth from '@spot-spotview/hooks/useProtectedByAuth.hook';

import useAuth from '@spot/shared-hooks/useAuth.hook';

import ReportsScreen from './screens/Reports.screen';

const DashboardModule = () => {
  const { signedIn } = useAuth();

  useProtectedByAuth();

  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (signedIn) {
      dispatch(dAction.serviceGetAll());
    }
  });

  return (
    <>
      <Switch>
        <Route exact path={REPORTS_ROUTES.ROOT} component={ReportsScreen} />
      </Switch>
    </>
  );
};

export default DashboardModule;
