import reducer from './blockchain.reducer';
import * as selector from './blockchain.selector';
import * as constant from './blockchain.constant';
import * as action from './blockchain.action';
import subscriber from './blockchain.subscriber';
import saga from './blockchain.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
