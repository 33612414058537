import { Box, Typography } from '@mui/material';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';

const BoxSummaryComponent = ({ loading = false, label, amount, img = '' }) => {
  return (
    <BoxWhite loading={loading}>
      <Box display="flex" gap={2} alignItems="center">
        <Box overflow="hidden" borderRadius="50%">
          <img
            src={img || 'https://picsum.photos/48/48'}
            style={{ display: 'block' }}
          />
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="subtitle2" color="gray.400">
            {label}
          </Typography>
          <Typography fontWeight={800} color="secondary.main">
            {amount}
          </Typography>
        </Box>
      </Box>
    </BoxWhite>
  );
};

export default BoxSummaryComponent;
