import reducer from './operation.reducer';
import * as constant from './operation.constant';
import * as action from './operation.action';
import * as selector from './operation.selector';
import saga from './operation.saga';
import initialState from './operation.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
