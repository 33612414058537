import reducer from './weather.reducer';
import * as constant from './weather.constant';
import * as action from './weather.action';
import * as selector from './weather.selector';
import saga from './weather.saga';
import initialState from './weather.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
