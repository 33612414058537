import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';
import router from '@spot/shared-store/router';

import ROUTES from '@spot-spotview/constants/routes.constant';

import getApiClientsService from '@spot/shared-services/user/getApiClients.service';
import getApiClientsIdService from '@spot/shared-services/user/getApiClientsId.service';
import patchApiClientsIdService from '@spot/shared-services/user/patchApiClientsId.service';
import postApiClientsService from '@spot/shared-services/user/postApiClients.service';

import currentState from '.';

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(currentState.selector.selectFilters),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiClientsService(seletors.filters)
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetId = function* (action) {
  const { payload } = action;

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() => getApiClientsIdService(payload));

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const seletors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      patchApiClientsIdService,
      payload?.id,
      {
        name: payload?.name,
        document_number: payload?.document_number,
        lat: payload?.lat,
        lon: payload?.lon,
        zip_code: payload?.zip_code,
        city: payload?.city,
        complement: payload?.complement,
        neighborhood: payload?.neighborhood,
        number: payload?.number,
        state: payload?.state,
        street: payload?.street,
        sponsor: payload?.sponsor,
        sponsor_email: payload?.sponsor_email,
        sponsor_phone: payload?.sponsor_phone,
        sponsor_ramal: payload?.sponsor_ramal,
        datasource_id: String(payload?.datasource_id),
        cli_plan_id: String(payload?.cli_plan_id),
        api_key: payload?.api_key,
      }
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Empresa atualizada com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(seletors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePost = function* (action) {
  const seletors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    push: flow(router.action.push, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(postApiClientsService, {
      name: payload?.name,
      document_number: payload?.document_number,
      lat: Number(payload?.lat),
      lon: Number(payload?.lon),
      zip_code: payload?.zip_code,
      city: payload?.city,
      complement: payload?.complement,
      neighborhood: payload?.neighborhood,
      number: payload?.number,
      state: payload?.state,
      street: payload?.street,
      sponsor: payload?.sponsor,
      sponsor_email: payload?.sponsor_email,
      sponsor_phone: payload?.sponsor_phone,
      sponsor_ramal: payload?.sponsor_ramal,
      datasource_id: payload?.datasource_id,
      cli_plan_id: payload?.cli_plan_id,
      api_key: payload?.api_key,
    });

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Empresa adicionada com sucesso',
      variant: 'success',
    });

    yield handlers.push(ROUTES.PROFILE.CREATE_COMPANY);

    return yield handlers.fetchEnd(seletors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );
}

export default {
  watch,
};
