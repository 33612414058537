import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function deleteApiAccountsIdProfileService(
  account_id: string,
  profile_id: string
) {
  const url = ['accounts', `${account_id}`, 'profile', `${profile_id}`].join(
    '/'
  );

  return fetch(url, {
    method: 'DELETE',
    ms: 'ACCOUNT',
    auth: true,
  });
}
