import { ACTION_TYPES } from './dialogDeleteRelation.constant';

export const open = (payload) => ({
  type: ACTION_TYPES.SET.OPEN,
  payload,
});

export const close = () => ({
  type: ACTION_TYPES.SET.CLOSE,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const accountDelete = () => ({
  type: ACTION_TYPES.RELATION.DELETE,
});
