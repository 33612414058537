import React from 'react';
import { Box, Typography, IconButton, Dialog, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { flow } from 'lodash';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import { v4 } from 'uuid';
import { AiOutlineCloudDownload } from '@react-icons/all-files/ai/AiOutlineCloudDownload';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AppLayout from '@spot-spotview/layouts/App.layout';
import BoxFilter from '@spot-spotview/components/Box/BoxFilter.component';
import toDateHourUtil from '@spot-spotview/utils/toDateHour.util';

import TableComponent from '@spot-spotview/components/Table.component';
import getApiReportsRegisterblockchainByIdService from '@spot/shared-services/report/getApiReportsRegisterblockchainById.service';

import filtersState from '../store/filters';
import reports from '../store/reports';
import reportsModule from '../store';
import toaster from '@spot/shared-store/toaster';

const ReportsScreen = () => {
  const [showDate, setShowDate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const selectorRedux = {
    filters: useSelector(filtersState.selector.selectState),
    dataReports: useSelector(reports.selector.selectDataResults),
    dataReportsMetadata: useSelector(reports.selector.selectDataMetadata),
    loadingReports: useSelector(reports.selector.selectLoading),
  };

  const dispatchRedux = {
    updateFilters: flow(filtersState.action.updateFilters, dispatch),
    resetState: flow(filtersState.action.resetState, dispatch),
    serviceGetAll: flow(reportsModule.action.serviceGetAll, dispatch),
    show: flow(toaster.action.show, dispatch),
  };

  const fields = [
    [
      {
        label: 'Início',
        name: 'start_created_at',
        variant: 'date',
        textFieldProps: {
          // onClick: () => setShowDate(true),
          InputProps: {
            endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
            readOnly: true,
          },
        },
      },
      {
        label: 'Fim',
        name: 'end_created_at',
        variant: 'date',
        textFieldProps: {
          // onClick: () => setShowDate(true),
          InputProps: {
            endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
            readOnly: true,
          },
        },
      },
      {
        label: 'Hash code',
        name: 'hash_code',
      },
    ],
    [
      {
        label: 'Bacen',
        name: 'bacen_code',
      },
      {
        label: 'Operação',
        name: 'code',
      },
      {
        label: 'CPF',
        name: 'document_number',
        variant: 'cpf',
      },
    ],
  ];

  const filter = (
    <Formik
      enableReinitialize
      initialValues={selectorRedux.filters}
      onSubmit={async (values) => {
        dispatchRedux.updateFilters(values);

        return true;
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <BoxFilter
            formik
            fields={fields}
            title="Data de criação"
            onEmpty={() => {
              dispatchRedux.resetState();
              dispatchRedux.serviceGetAll();
            }}
          />

          <Dialog open={showDate} onClose={() => setShowDate(false)}>
            <DateRange
              weekdayDisplayFormat="EEEEEE"
              monthDisplayFormat="MMMM yyyy"
              locale={ptBR}
              showDateDisplay={false}
              months={2}
              direction="horizontal"
              ranges={[
                {
                  startDate: values.start_created_at || new Date(),
                  endDate: values.end_created_at || new Date(),
                  key: 'selection',
                },
              ]}
              onChange={({ selection }) => {
                setFieldValue('start_created_at', selection.startDate);
                setFieldValue('end_created_at', selection.endDate);
              }}
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );

  const columns = [
    {
      label: 'Operação',
      name: 'code',
    },
    {
      label: 'Bacen',
      name: 'bacen',
    },
    {
      label: 'CPF',
      name: 'owner_document',
    },
    {
      label: 'Data',
      name: 'created',
      render: ({ created }) => (
        <Typography color="gray.400" variant="subtitle2">
          {toDateHourUtil(created)}
        </Typography>
      ),
    },
    {
      label: 'Hash',
      name: 'blockchain.hash_code',
      render: ({ blockchain }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            color="gray.400"
            variant="subtitle2"
            fontFamily={'monospace'}
          >
            {blockchain?.hash_code}
          </Typography>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(blockchain?.hash_code);

              dispatchRedux.show({
                message: 'Hash copiada',
                variant: 'success',
              });
            }}
          >
            <ContentCopyIcon sx={{ fontSize: 16, color: 'gray.400' }} />
          </IconButton>
        </Box>
      ),
    },
    {
      label: 'Download',
      name: 'url',
      render: ({ id }) => (
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            onClick={async () => {
              setLoading(true);

              const [success, result] =
                (await getApiReportsRegisterblockchainByIdService(id)) as any;

              if (success) {
                window.open(result.url, '_blank');
              }

              setLoading(false);
            }}
          >
            <AiOutlineCloudDownload color="#3385FF" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const table = (
    <TableComponent
      columns={columns}
      name="reports"
      loading={selectorRedux.loadingReports || loading}
      data={selectorRedux.dataReports?.rows}
      metadata={selectorRedux.dataReportsMetadata?.resultset}
      handleUpdateFilters={dispatchRedux.updateFilters}
    />
  );

  return (
    <AppLayout title="Relatórios">
      <Box display="flex" flexDirection="column" gap={3}>
        {filter}
        {table}
      </Box>
    </AppLayout>
  );
};

export default ReportsScreen;
