import reducer from './clients.reducer';
import * as constant from './clients.constant';
import * as action from './clients.action';
import * as selector from './clients.selector';
import saga from './clients.saga';
import initialState from './clients.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
