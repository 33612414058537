import { ACTION_TYPES } from './totals.constant';

export const serviceGetTotals = (payload: { filters: any }) => ({
  type: ACTION_TYPES.SERVICE.GET_TOTALS,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET.FILTERS,
});
