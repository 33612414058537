import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset: string;
  limit: string;
  bacen: string;
  code: string;
  begin_date: string;
  end_date: string;
  uf: string;
  city: string;
  culture_name: string;
  cycle: string;
  alert_type: string;
};

export default async function getApiDashboardsDetailsService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters, { skipNulls: true });

  const url = ['dashboards/details', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'DASHBOARD',
    auth: true,
  });
}
