import reducer from './dialogDeleteRelation.reducer';
import * as action from './dialogDeleteRelation.action';
import * as selector from './dialogDeleteRelation.selector';
import * as constant from './dialogDeleteRelation.constant';
import initialState from './dialogDeleteRelation.initialState';
import subscriber from './dialogDeleteRelation.subscriber';
import saga from './dialogDeleteRelation.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
