import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  profile_id: string;
  emails: Array<string>;
};

export default async function postApiUsersIdInvitesService(
  id,
  payload: Payload
) {
  const url = ['accounts', id, 'invite'].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
