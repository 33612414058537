import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiOperationsIdService(id: string) {
  const url = ['operations', `${id}`].join('/');

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
    cache: 'no-cache',
  });
}
