import { combineReducers } from 'redux';

import dialogDeleteAccount from './dialogDeleteAccount';
import dialogDeleteProfile from './dialogDeleteProfile';
import dialogDeleteRelation from './dialogDeleteRelation';
import dialogInviteAccount from './dialogInviteAccount';

const reducer = combineReducers({
  [dialogDeleteAccount.name]: dialogDeleteAccount.reducer,
  [dialogDeleteProfile.name]: dialogDeleteProfile.reducer,
  [dialogInviteAccount.name]: dialogInviteAccount.reducer,
  [dialogDeleteRelation.name]: dialogDeleteRelation.reducer,
});

export default reducer;
