import reducer from './invoices.reducer';
import * as constant from './invoices.constant';
import * as action from './invoices.action';
import * as selector from './invoices.selector';
import saga from './invoices.saga';
import initialState from './invoices.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
