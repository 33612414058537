import React from 'react';
import {
  Switch as Routes,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSelector, useDispatch } from 'react-redux';

import useNotification from '@spot/shared-hooks/useNotification.hook';

import LoginModule from './modules/login/Login.module';
import DashboardModule from './modules/dashboard/Dashboard.module';
import ReportsModule from './modules/reports/Reports.module';
import OperationsModule from './modules/operations/Operations.module';
import ProfileModule from './modules/profile/Profile.module';
import PolygonsModule from './modules/polygons/Polygons.module';
import BlockchainModule from './modules/blockchain/Blockchain.module';

import ROUTES from './constants/routes.constant';

import auth from '@spot/shared-store/auth';

import useEffectOnce from './hooks/useEffectOnce.hook';

import user from './modules/profile/store/currentUser';
import domain from './modules/dashboard/store/domain';

const RoutesModule = () => {
  const dispatch = useDispatch();

  const signedIn = useSelector(auth.selector.selectSignedIn);

  const { pathname } = useLocation();

  useNotification();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffectOnce(() => {
    if (process.env.REACT_APP_LOGROCKET === 'true') {
      setupLogRocketReact(LogRocket);

      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID || '');
    }
  });

  useEffectOnce(() => {
    if (signedIn) {
      dispatch(user.action.serviceGetUserId());
    }
  });

  return (
    <>
      <Routes>
        <Route path={ROUTES.DASHBOARD.ROOT} component={DashboardModule} />
        <Route path={ROUTES.REPORTS.ROOT} component={ReportsModule} />
        <Route path={ROUTES.OPERATIONS.ROOT} component={OperationsModule} />
        <Route path={ROUTES.PROFILE.ROOT} component={ProfileModule} />
        <Route path={ROUTES.POLYGONS.ROOT} component={PolygonsModule} />
        <Route path={ROUTES.BLOCKCHAIN.ROOT} component={BlockchainModule} />
        <Route path={ROUTES.LOGIN.ROOT} component={LoginModule} />

        <Redirect to={ROUTES.DASHBOARD.ROOT} />
      </Routes>
    </>
  );
};

export default RoutesModule;
