import { format, lastDayOfMonth } from 'date-fns';

const today = new Date();
const firstDateOfMonth = format(today, 'yyyy/MM/01');
const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy/MM/dd');

const initialState = {
  offset: '0',
  limit: '20',
  code: '',
  bacen_code: '',
  document_number: '',
  hash_code: '',
  start_created_at: '',
  end_created_at: '',
};

export default initialState;
