import React, { Fragment } from 'react';
import {
  Box,
  Divider,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import format from 'date-fns/format';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import InsertChartTwoToneIcon from '@mui/icons-material/InsertChartTwoTone';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { InsightsTwoTone } from '@mui/icons-material';

import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';

import TableComponent from '@spot-spotview/components/Table.component';
import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import BoxGray from '@spot-spotview/components/Box/BoxGray.component';
import ChartWeatherComponent from '../components/ChartWeather.component';
import MapComplianceComponent from '../components/MapCompliance.component';
import MapGlebasComponent from '../components/MapGlebas.component';
import ChartNDVIComponent from '../components/ChartNDVI.component';
import BoxOperationComponent from '../components/BoxOperation.component';
import BoxReportComponent from '../components/BoxReport.component';

import PrintLayout from '../layouts/Print.layout';

import toDateUtil from '@spot-spotview/utils/toDate.util';
import toDateExtenseUtil from '@spot-spotview/utils/toDateExtense.util';
import toDateHourUtil from '@spot-spotview/utils/toDateHour.util';
import toCurrency from '@spot-spotview/utils/toCurrency.util';

import operation from '../store/operation';
import compliance from '../store/compliance';
import weather from '../store/weather';
import ndvi from '../store/ndvi';
import ROUTES from '@spot-spotview/constants/routes.constant';

const BoxOperation = BoxOperationComponent;

const BoxReport = BoxReportComponent;

const BoxA4 = ({ children, loading = false }) => {
  return (
    <Box
      width="210mm"
      height="297mm"
      bgcolor="white"
      position="relative"
      className="boxa4"
    >
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          bgcolor="white"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const Header = ({ title, section, bacen }) => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Box
      display="grid"
      gridTemplateColumns="33.33% 33.33% 33.33%"
      alignItems="center"
    >
      <img style={{ height: '60px' }} src="/img/logo-spoteye.svg" />
      <Box>
        <Typography
          align="center"
          sx={{ textTransform: 'uppercase' }}
          variant="h3"
          color="gray.300"
        >
          {section}
          <Typography
            align="center"
            sx={{ textTransform: 'uppercase' }}
            variant="h6"
            color="gray.300"
          >
            {title}
          </Typography>
        </Typography>
      </Box>
      <Typography align="right">
        <img style={{ height: '60px' }} src="/img/logo-spotsat.png" />{' '}
      </Typography>
    </Box>
  </Box>
);

const OperationsIdScreen = () => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = React.useState(2);

  const history = useHistory();
  const { id: idRouter }: any = useParams();

  const dispatchRedux = {
    serviceGet: flow(operation.action.serviceGet, dispatch),
    serviceGetCompliance: flow(compliance.action.serviceGet, dispatch),
    serviceGetWeather: flow(weather.action.serviceGet, dispatch),
    serviceGetNdvi: flow(ndvi.action.serviceGet, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(operation.selector.selectLoading),
    loadingCompliance: useSelector(compliance.selector.selectLoading),
    loadingNdvi: useSelector(ndvi.selector.selectLoading),
    data: useSelector(operation.selector.selectData),
    dataCompliance: useSelector(compliance.selector.selectData),
  };

  const loadingAllArr = [
    selectorRedux.loading,
    selectorRedux.loadingCompliance,
    selectorRedux.loadingNdvi,
  ];

  const loadingAll = loadingAllArr.some((e) => !!e);

  function makePDF() {
    const quotes: any = document.querySelector('root');

    html2canvas(quotes, {
      onrendered: function (canvas) {
        const pdf = new jsPDF('p', 'pt', 'letter');
      },
    } as any);
  }

  React.useEffect(() => {
    if (loadingAllArr.every((l) => l === false)) {
      setTimeout(() => {
        const tempTitle = document.title;
        document.title = `laudo_${selectorRedux?.data?.bacen_code}.pdf`;
        // window.print();
        document.title = tempTitle;
      }, 2000);
    }
  }, [loadingAll]);

  useEffectOnce(() => {
    if (idRouter) {
      dispatchRedux.serviceGet(idRouter);
      dispatchRedux.serviceGetCompliance(idRouter);
      dispatchRedux.serviceGetWeather(idRouter);
      dispatchRedux.serviceGetNdvi(idRouter);
    }
  });

  const infos = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" gap={5}>
      {[
        {
          label: 'Ref. BACEN',
          amount: selectorRedux.data?.bacen_code,
        },
        {
          label: 'Número da operação',
          amount: selectorRedux.data?.code,
        },
        {
          label: 'Nome',
          amount: selectorRedux.data?.owner,
        },
        {
          label: 'CPF',
          amount: selectorRedux.data?.owner_document,
        },
        {
          label: 'CNPJ cooperativa',
          amount: selectorRedux.data?.company?.document_number,
        },
        {
          label: 'Financiador',
          amount: selectorRedux.data?.company?.name,
        },
        {
          label: 'Valor financiado',
          amount: toCurrency(selectorRedux.data?.total_financed),
        },
        {
          label: 'Cultura financiada',
          amount: selectorRedux.data?.validated_culture,
        },
        {
          label: 'Área financiada',
          amount: `${selectorRedux.data?.financed_area} ${selectorRedux.data?.area_type}`,
        },
        {
          label: 'Localização',
          amount: `${selectorRedux.data?.address?.city} - ${selectorRedux.data?.address?.state}`,
        },
        {
          label: 'Latitude / Longitude',
          amount: `${selectorRedux.data?.coordinates?.lat} / ${selectorRedux.data?.coordinates?.lng}`,
          gridColumn: 'span 2',
        },
      ].map((v, index) => (
        <BoxOperation key={`box-operation-${index}`} {...v} />
      ))}
    </Box>
  );

  const alerts = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <ReportProblemTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Alertas ({selectorRedux.data?.alerts?.length})
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        {selectorRedux.data?.alerts?.map((a) => (
          <Typography
            variant="subtitle1"
            color="gray.black"
            fontWeight={500}
            sx={{ bgcolor: 'warning.light3', p: 2, borderRadius: '6px' }}
            key={a?.id}
          >
            {a?.description}
          </Typography>
        ))}
      </Box>
    </Box>
  );

  const responsibility = (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography color="secondary.main" fontWeight={600}>
        Responsabilidade da Análise
      </Typography>
      <Typography color="gray.400" variant="subtitle2">
        Responsável pela análise: <strong>SpotSat LTDA</strong>. Interpretação e
        Elaboração de Conclusões: <strong>SpotEye</strong>. Responsável pelo
        Sensoriamento Remoto: <strong>SpotSat LTDA</strong>. Instituição
        Financeira e Responsável:{' '}
        <strong>{selectorRedux.data?.company?.name}</strong>
      </Typography>
    </Box>
  );

  const technicaldata = (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography color="secondary.main" fontWeight={600}>
        Dados Técnicos
      </Typography>
      <Typography color="gray.400" variant="subtitle2">
        Satélite <strong>Sentinel 2</strong> | Sensor <strong>MSI</strong> |
        Coleta de{' '}
        <strong>{toDateUtil(selectorRedux.data?.planting_start)}</strong> à{' '}
        <strong>{toDateUtil(selectorRedux.data?.planting_end)}</strong> |
        Resolução espacial de <strong>10m</strong> | Resolução radiométrica de{' '}
        <strong>12bits/pixel</strong> | Bandas utilizadas:{' '}
        <strong>B08, B04</strong> | Pré-processamento com método correção{' '}
        <strong>TOA</strong> | Processamento das imagens:{' '}
        <strong>NDVI e EVI</strong>
      </Typography>
    </Box>
  );

  const buttonBack = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Button
          onClick={() => {
            if (history.length <= 2) {
              history.push(ROUTES.OPERATIONS.ID(idRouter));
            } else {
              history.goBack();
            }
          }}
          startIcon={<ArrowBackIcon />}
          sx={{ color: 'info.main' }}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );

  const createdUpdate = (
    <Box display="flex" gap={2}>
      <Typography
        variant="subtitle2"
        color="secondary.main"
        sx={{
          bgcolor: 'secondary.light3',
          px: '8px',
          py: '4px',
          borderRadius: 0.5,
        }}
      >
        criado em: {toDateHourUtil(selectorRedux.data?.created)}
      </Typography>
      <Typography
        variant="subtitle2"
        color="secondary.main"
        sx={{
          bgcolor: 'secondary.light3',
          px: '8px',
          py: '4px',
          borderRadius: 0.5,
        }}
      >
        atualizado em: {toDateHourUtil(selectorRedux.data?.modified)}
      </Typography>
      <Typography
        variant="subtitle2"
        color="secondary.main"
        sx={{
          bgcolor: 'secondary.light3',
          px: '8px',
          py: '4px',
          borderRadius: 0.5,
        }}
      >
        emissão em: {format(new Date(), 'dd/MM/yyyy HH:mm')}
      </Typography>
    </Box>
  );

  const analyticsSocial = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={1} alignItems="center">
        <InsertChartTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Análise Socioambiental
        </Typography>
      </Box>

      <Box display="flex" gap={2}>
        <BoxReport
          label="Área Total Financiada"
          amount={`${selectorRedux.data?.financed_area} ${selectorRedux.data?.area_type}`}
        />
        <BoxReport
          label="Área Total Informada"
          amount={`${selectorRedux.data?.area} ${selectorRedux.data?.area_type}`}
        />
        <BoxReport
          label="Área Total de Plantio"
          amount={`${selectorRedux.data?.planting_area_total} ${selectorRedux.data?.area_type}`}
        />
      </Box>
    </Box>
  );

  const talhoes = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={1} alignItems="center">
        <MapOutlinedIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Talhões
        </Typography>
      </Box>

      <TableComponent
        pagination={false}
        name="agricola"
        data={[
          {
            n: selectorRedux.data?.code,
            area: `${selectorRedux.data?.area} ${selectorRedux.data?.area_type}`,
            zoneamento: '',
            plantio: `${toDateUtil(
              selectorRedux.data?.planting_start
            )} a ${toDateUtil(selectorRedux.data?.planting_end)}`,
            colheita: `${toDateUtil(
              selectorRedux.data?.crop_start
            )} a ${toDateUtil(selectorRedux.data?.crop_end)}`,
          },
        ]}
        columns={[
          {
            name: 'n',
            label: 'Nº',
          },
          {
            name: 'area',
            label: 'Área',
          },
          {
            name: 'zoneamento',
            label: 'Zoneamento Agrícola',
          },
          {
            name: 'plantio',
            label: 'Plantio',
          },
          {
            name: 'colheita',
            label: 'Colheita',
          },
        ]}
      />
    </Box>
  );

  const production = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <AssistantPhotoTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Produção
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
        <BoxReport
          label="Previsão de Plantio"
          amount={toDateUtil(selectorRedux.data?.planting_start)}
        />
        <BoxReport
          label="Previsão da Colheita"
          amount={toDateUtil(selectorRedux.data?.crop_start)}
        />
        <BoxReport
          label="Produtividade Média da Região"
          amount={`${selectorRedux.data?.production_average_region} ${selectorRedux.data?.production_average_region_unity} / ${selectorRedux.data?.area_type}`}
        />
        <BoxReport label="Tipo de Solo" amount={selectorRedux.data?.soil} />
      </Box>
    </Box>
  );

  const analyticsCredit = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <ArticleTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Análise para Manual de Crédito Rural
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
        <BoxReport
          label="Cultura"
          amount={selectorRedux.data?.validated_culture}
        />
        <BoxReport
          label="Precisão"
          amount={`${selectorRedux.data?.precision} %`}
        />
      </Box>
    </Box>
  );

  const weatherChart = (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <ThermostatIcon color="primary" sx={{ fontSize: 22 }} />
        <Typography variant="subtitle1" fontWeight={500} color="secondary.main">
          Gráfico de Clima
        </Typography>
      </Box>
      <Box height="234px">
        <ChartWeatherComponent printMode />
      </Box>
    </Box>
  );

  const mapCompliance = (
    <Box height="298px">
      <MapComplianceComponent
        printMode
        geom={selectorRedux.data?.glebes?.map((g) => g.geom)}
      />
    </Box>
  );

  const tableCompliance = (
    <TableComponent
      pagination={false}
      name="environmentalCompliance"
      data={selectorRedux.dataCompliance?.summary}
      columns={[
        {
          name: 'name',
          label: 'Camada',
        },
        {
          name: 'intersection_area_ha',
          label: 'Área (ha)',
        },
        {
          name: 'intersection_area_percentage',
          label: 'Área (%)',
          render: ({ intersection_area_percentage }) => (
            <Typography color="gray.400" variant="subtitle2">
              {Number(intersection_area_percentage).toLocaleString('pt-br', {
                minimumFractionDigits: 3,
              })}
            </Typography>
          ),
        },
      ]}
    />
  );

  return (
    <PrintLayout>
      <Box display="flex" flexDirection="column" gap={3}>
        {buttonBack}

        <BoxA4 loading={loadingAll}>
          <Box display="flex" flexDirection="column" p={3} gap={3}>
            <Box mb={5}>
              <Header
                bacen={selectorRedux.data?.bacen_code}
                title="Relatório"
                section="Laudo agrícola"
              />
            </Box>
            <Divider />
            <Box display="flex" gap={1} alignItems="center">
              <ArticleTwoToneIcon color="primary" sx={{ fontSize: 22 }} />
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color="secondary.main"
              >
                Dados da Operação
              </Typography>
            </Box>
            {createdUpdate}
            {infos}
            <Divider />
            {alerts}
          </Box>
        </BoxA4>
        <BoxA4 loading={loadingAll}>
          <Box display="flex" flexDirection="column" p={3} gap={3}>
            {production}
            {analyticsCredit}
            {weatherChart}
            {analyticsSocial}
          </Box>
        </BoxA4>

        {selectorRedux.data?.glebes?.map((g, idx) => (
          <Fragment key={g?.id}>
            <BoxA4 loading={loadingAll}>
              <Box display="flex" flexDirection="column" p={3} gap={3}>
                <Box display="flex" flexDirection="column" gap={3} key={g?.id}>
                  <Box display="flex" gap={3}>
                    <Button variant="contained" sx={{ color: 'white' }}>
                      Gleba {idx + 1}
                    </Button>
                  </Box>

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(4, 1fr)"
                    gap={2}
                  >
                    <BoxReport
                      label="Área Informada"
                      amount={`${g?.area} ha`}
                    />
                    <BoxReport
                      label="Área de Plantio"
                      amount={`${g?.planting_area} ha`}
                    />
                    <BoxReport
                      label="Estágio Vegetativo Alcançado"
                      amount={g?.last_stage_screen}
                    />
                    <BoxReport
                      label="Coordenadas Geodésicas"
                      amount={g?.geodetic_coordinate}
                    />
                  </Box>

                  <Box height="300px">
                    <MapGlebasComponent
                      geom={g?.geom}
                      geom_planting={g?.geom_planting}
                      compliance={g?.compliance?.details}
                      printMode
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" gap={3}>
                    <Typography
                      variant="subtitle2"
                      color="gray.400"
                      fontFamily={'monospace'}
                    >
                      {g?.coordinates_area
                        .map((c) => [`[Lat: ${c.lat} Lon: ${c.lng}]`])
                        .join(', ')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </BoxA4>
            <BoxA4 loading={loadingAll}>
              <Box display="flex" flexDirection="column" p={3} gap={3}>
                <Box display="flex" flexDirection="column" gap={3} key={g?.id}>
                  <Box display="flex" flexDirection="column" gap={0}>
                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 1fr 1fr auto"
                      alignItems="center"
                      gap={2}
                    >
                      {g?.images?.map((img, index) => (
                        <BoxGray key={`img-gleba-${img.url}-${index}`}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignSelf="center"
                            sx={{
                              minHeight: 171,
                            }}
                          >
                            <Typography textAlign="center">
                              <img
                                src={img?.url}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  maxWidth: '200px',
                                  maxHeight: '250px',
                                }}
                                alt={img?.text}
                              />
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="gray.300"
                              fontWeight={500}
                              textAlign="center"
                              sx={{
                                py: 1,
                              }}
                            >
                              {img?.text}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="gray.black"
                              fontWeight={500}
                              textAlign="center"
                            >
                              {img?.date && toDateExtenseUtil(img?.date)}
                            </Typography>
                          </Box>
                        </BoxGray>
                      ))}

                      <Box>
                        <img
                          src="/img/agro_palette.png"
                          style={{
                            display: 'block',
                            height: 250,
                          }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" gap={1} alignItems="center">
                      <InsightsTwoTone color="primary" sx={{ fontSize: 22 }} />
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        color="secondary.main"
                      >
                        Gráfico NDVI
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      sx={{
                        '.js-plotly-plot': {
                          width: '100%',
                        },
                        '.plotly > div': {
                          width: '100% !important',
                        },
                      }}
                    >
                      <ChartNDVIComponent
                        printMode
                        height={340}
                        glebaId={g?.id}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </BoxA4>
          </Fragment>
        ))}

        <BoxA4 loading={loadingAll}>
          <Box display="flex" flexDirection="column" p={3} gap={3}>
            {talhoes}
            {responsibility}
            {technicaldata}
          </Box>
        </BoxA4>

        <BoxA4 loading={loadingAll}>
          <Box display="flex" flexDirection="column" p={3} gap={3}>
            <Box mb={5}>
              <Header
                bacen={selectorRedux.data?.bacen_code}
                title="Relatório"
                section="Compliance ambiental"
              />
            </Box>

            {mapCompliance}

            {tableCompliance}
          </Box>
        </BoxA4>
      </Box>
    </PrintLayout>
  );
};

export default OperationsIdScreen;
