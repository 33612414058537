import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import auth from '@spot/shared-store/auth';

export default function useProtectedByAuth() {
  const history = useHistory();

  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
  };

  const { signedIn } = selectorRedux;

  useEffect(() => {
    const variant = 'spotview';

    const currenthref = window.location.href;

    if (!signedIn) {
      window.location.href = `${process.env.REACT_APP_URL_SPOTAUTH}/login?redirectUrl=${currenthref}&variant=${variant}`;
    }
  }, [signedIn]);
}
