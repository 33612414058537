import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

const MapComponent = () => {
  return (
    <MapContainer
      className="markercluster-map"
      center={[51.0, 19.0]}
      zoom={4}
      maxZoom={18}
      style={{ height: '100%' }}
    >
      <TileLayer
        subdomains={['clarity']}
        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
      />

      {/* Put <MarkerClusterGroup {...props} /> inside react-leaflet after <TileLayer /> */}
    </MapContainer>
  );
};

export default MapComponent;
