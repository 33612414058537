import React, { useEffect, useRef } from 'react';
import { Box, Divider, Typography, Avatar, IconButton } from '@mui/material';
import { LoadingButton as Button } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableComponent from '@spot-spotview/components/Table.component';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { flow } from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import messages from '@spot/shared-constants/messagesSchema.constant';

import FormArrFormikComponent from '@spot-spotview/components/FormArrFormik.component';
import FieldUploadPhoto from '@spot-spotview/components/FieldUpload.component';
import DeleteIcon from '@mui/icons-material/Delete';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import BoxGray from '@spot-spotview/components/Box/BoxGray.component';

import AppLayout from '@spot-spotview/layouts/App.layout';
import PROFILE_ROUTES from '../constants/profileRoutes.constant';

import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';
import accounts from '../store/accounts';
import dialogDeleteRelation from '../store/dialogs/dialogDeleteRelation';

const ProfileEditUserScreen = () => {
  const params: any = useParams();
  const [currentTab, setCurrentTab] = React.useState(1);
  const [hasPhoto, setHasPhoto] = React.useState<any>(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataAccounts: useSelector(accounts.selector.selectData),
    loadingAccounts: useSelector(accounts.selector.selectLoading),
  };

  const dispatchRedux = {
    servicePatch: flow(accounts.action.servicePatch, dispatch),
    serviceGetId: flow(accounts.action.serviceGetId, dispatch),
    resetState: flow(accounts.action.resetState, dispatch),
    openDelete: flow(dialogDeleteRelation.action.open, dispatch),
  };

  useEffectOnce(() => {
    if (params.id) {
      const account = dispatchRedux.serviceGetId({ account_id: params.id });
    }
  });

  useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  React.useEffect(() => {
    if (selectorRedux.dataAccounts?.photo) {
      setHasPhoto(selectorRedux.dataAccounts?.photo);
    }
  }, [selectorRedux.dataAccounts]);

  const up = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            onClick={() => {
              dispatchRedux.resetState();
              history.goBack();
            }}
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'info.main' }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const body = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography fontSize="18px" fontWeight={700} color="secondary.main">
        Editar usuário
      </Typography>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          account_id: params.id,
          nome: selectorRedux.dataAccounts?.name,
          email: selectorRedux.dataAccounts?.email,
          senha: '',
          confirmasenha: '',
        }}
        onSubmit={async (values) => {
          dispatchRedux.servicePatch(values);

          return true;
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(messages.EMAIL),
          senha: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
              messages.PASSWORD_SPECS
            )
            .oneOf([Yup.ref('confirmasenha'), ''], 'As senhas não são iguais'),
          confirmasenha: Yup.string().oneOf(
            [Yup.ref('senha'), ''],
            'As senhas não são iguais'
          ),
        })}
      >
        {({ submitForm, errors, values, setFieldValue }) => (
          <Form key={`profile-form-1-${selectorRedux.dataAccounts}`}>
            <Box
              display="grid"
              gridTemplateColumns="300px 1fr"
              gap={4}
              alignItems="flex-start"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                borderRadius="6px"
              >
                {hasPhoto && (
                  <Box display="flex" flexDirection="column">
                    <img src={hasPhoto} style={{ maxWidth: '100%' }} />

                    <Button
                      onClick={() => {
                        setHasPhoto(false);
                        setFieldValue('photo', null);
                      }}
                    >
                      Remover foto
                    </Button>
                  </Box>
                )}

                {!hasPhoto && <FieldUploadPhoto name="photo" />}
              </Box>

              <Box display="flex" flexDirection="column" gap={4}>
                <FormArrFormikComponent
                  boxProps={{
                    gap: 4,
                  }}
                  fields={[
                    [
                      {
                        label: 'Nome',
                        name: 'nome',
                      },
                    ],
                    [
                      {
                        label: 'E-mail',
                        name: 'email',
                      },
                    ],
                    [
                      {
                        label: 'Senha',
                        name: 'senha',
                        variant: 'password',
                      },
                      {
                        label: 'Repetir senha',
                        name: 'confirmasenha',
                        variant: 'password',
                      },
                    ],
                  ]}
                />

                <Box>
                  <Typography>Perfis</Typography>
                  <TableComponent
                    name="profiles"
                    component={false}
                    pagination={false}
                    data={selectorRedux.dataAccounts?.profiles}
                    loading={selectorRedux.loadingAccounts}
                    columns={[
                      {
                        label: 'Status',
                        name: 'profile.active',
                        render: (props) => (
                          <Box display="flex" alignItems="center" gap={2}>
                            <Box
                              width={8}
                              height={8}
                              bgcolor="primary.main"
                              borderRadius="50%"
                            />
                            <Typography
                              variant="subtitle2"
                              color="primary.main"
                            >
                              {props.profile.active ? 'Ativo' : 'Inativo'}
                            </Typography>
                          </Box>
                        ),
                      },
                      { label: 'Perfil', name: 'profile.description' },
                      {
                        name: 'actions',
                        render: ({ profile }) => (
                          <Box display="flex" justifyContent="space-around">
                            <Button
                              startIcon={<BorderColorIcon />}
                              sx={{ color: 'info.light' }}
                              onClick={() =>
                                history.push(
                                  PROFILE_ROUTES.EDIT_PROFILE(profile.id)
                                )
                              }
                            >
                              Editar
                            </Button>
                            <IconButton
                              sx={{ color: 'error.main' }}
                              onClick={() =>
                                dispatchRedux.openDelete({
                                  profile_id: profile.id,
                                  account_id: params.id,
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
                <Box alignSelf="end">
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                    loading={selectorRedux.loadingAccounts}
                    disabled={
                      Object.keys(errors).length > 0 ||
                      selectorRedux.loadingAccounts
                    }
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );

  return (
    <AppLayout title={false} breadcrumb={['Configurações', 'Editar usuário']}>
      <Box display="flex" flexDirection="column" gap={2}>
        <BoxWhite>
          <Box display="flex" flexDirection="column" gap={3}>
            {up}
            {body}
          </Box>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default ProfileEditUserScreen;
