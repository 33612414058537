import flow from 'lodash/flow';
import pickBy from 'lodash/pickBy';
import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import ROUTES from '@spot-spotview/constants/routes.constant';

import toaster from '@spot/shared-store/toaster';
import router from '@spot/shared-store/router';
import auth from '@spot/shared-store/auth';

import getApiProfilesService from '@spot/shared-services/profiles/getApiProfiles.service';
import getApiProfilesIdService from '@spot/shared-services/profiles/getApiProfilesId.service';
import patchApiProfilesIdService from '@spot/shared-services/profiles/patchApiProfilesId.service';
import postApiProfilesService from '@spot/shared-services/profiles/postApiProfiles.service';
import deleteApiProfilesIdService from '@spot/shared-services/profiles/deleteApiProfilesId.service';
import useAuth from '@spot/shared-hooks/useAuth.hook';

import getApiProfilesid from '@spot/shared-services/profiles/getApiProfilesId.service';

import currentState from '.';
import authenticate from '../authenticate';

const handleServiceGet = function* (action) {
  const selectors = {
    filters: yield select(currentState.selector.selectFilters),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiProfilesService({ ...selectors.filters, ...action.filters })
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    servicePostToken: flow(authenticate.action.servicePost, put),
    push: flow(router.action.push, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      patchApiProfilesIdService as any,
      payload?.id,
      pickBy({
        description: payload?.description,
        cooperatives_ids: payload?.cooperatives_ids,
      })
    );

    if (!success) {
      throw result;
    }

    yield handlers.servicePostToken({ token: payload.token });

    yield handlers.push(ROUTES.PROFILE.ROOT);

    yield handlers.show({
      message: 'Perfil atualizado com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceDelete = function* (action) {
  const selectors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload = {} } = action || {};

  const { id } = payload;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(deleteApiProfilesIdService, id);

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Perfil removido com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(getApiProfilesid, payload);

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePost = function* (action) {
  const seletors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    servicePostToken: flow(authenticate.action.servicePost, put),
    show: flow(toaster.action.show, put),
    push: flow(router.action.push, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(postApiProfilesService, {
      ...payload,
    });

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Perfil criado com sucesso',
      variant: 'success',
    });

    yield handlers.servicePostToken({ token: payload.token });

    yield handlers.push(ROUTES.PROFILE.CREATE_PROFILE);

    return yield handlers.fetchEnd(seletors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.DELETE,
    handleServiceDelete
  );
}

export default {
  watch,
};
