import { useSelector, useDispatch } from 'react-redux';
import { ptBR } from '@mui/material/locale';
import {
  createTheme,
  ThemeProvider as ThemeProviderMUI,
  CssBaseline,
} from '@mui/material';

import themeConstants from '@spot/shared-constants/theme.constant';

import misc from '@spot-spotview/store/configs/misc';

const ThemeProvider = ({ children }) => {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;

  const themeconsts = themeConstants(mode);

  const theme = createTheme(
    {
      ...themeconsts,
      palette: {
        ...themeconsts.palette,
        primary: {
          ...themeconsts.palette.primary,
          main: '#C62101',
        },
      },
    } as any,
    ptBR
  );

  return (
    <ThemeProviderMUI theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProviderMUI>
  );
};

export default ThemeProvider;
