import { all } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';

import login from '@spot-spotview/modules/login/store';

import configs from './configs';

import profile from '@spot-spotview/modules/profile/store';
import dashboard from '@spot-spotview/modules/dashboard/store';
import operations from '@spot-spotview/modules/operations/store';
import polygons from '@spot-spotview/modules/polygons/store';
import reports from '@spot-spotview/modules/reports/store';
import blockchain from '@spot-spotview/modules/blockchain/store';

function* rootSaga() {
  yield all([
    login.saga.watch(),
    auth.saga.watch(),
    router.saga.watch(),
    configs.saga.watch(),
    dashboard.saga.watch(),
    profile.saga.watch(),
    operations.saga.watch(),
    blockchain.saga.watch(),
    reports.saga.watch(),
    polygons.saga.watch(),
  ]);
}

export default rootSaga;
