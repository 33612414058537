import { createReducer } from '@reduxjs/toolkit';

import initialState from './datasource.initialState';

import { ACTION_TYPES } from './datasource.constant';

const handleReset = () => initialState;

const handleResetFilters = (state) => {
  state.filters = initialState.filters;
};

const handleFetchStart = (state) => {
  state.loading = true;
};

const handleFetchEnd = (state, action) => {
  state.loading = false;
  state.data = action.payload;
  state.error = [false, null];
};

const handleFetchError = (state, action) => {
  state.loading = false;
  state.error = [true, action.payload];
};

const handleUpdateFilters = (state, action) => {
  state.loading = true;
  state.filters = Object.assign({}, state.filters, action.filters);
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleReset,
  [ACTION_TYPES.RESET.FILTERS]: handleResetFilters,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.END]: handleFetchEnd,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.UPDATE.FILTERS]: handleUpdateFilters,
});
