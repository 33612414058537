import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';

import toaster from '@spot/shared-store/toaster';

import getApiOperationsIdComplianceService from '@spot/shared-services/operation/getApiOperationsIdCompliance.service';

import currentState from '.';

const handleServiceGet = function* (action) {
  const { payload } = action;

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      getApiOperationsIdComplianceService,
      payload
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
