import { combineReducers } from 'redux';

import filters from './filters';
import domain from './domain';
import totals from './totals';
import operations from './operations';
import compliance from './compliance';
import operation from './operation';
import weather from './weather';
import ndvi from './ndvi';

const reducer = combineReducers({
  [ndvi.name]: ndvi.reducer,
  [weather.name]: weather.reducer,
  [operation.name]: operation.reducer,
  [compliance.name]: compliance.reducer,
  [operations.name]: operations.reducer,
  [totals.name]: totals.reducer,
  [domain.name]: domain.reducer,
  [filters.name]: filters.reducer,
});

export default reducer;
