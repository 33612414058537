import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function patchApiOperationsIdBookmarkService(
  id: string,
  payload = undefined
) {
  const url = ['operations', `${id}`, 'bookmark'].join('/');

  return fetch(url, {
    method: 'PATCH',
    ms: 'OPERATION',
    auth: true,
  });
}
