export const NAME = 'dialogInviteAccount';

const CONTEXT = 'PROFILE::DIALOGS::INVITE_ACCOUNT';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const SERVICE = {
  POST: SERVICE_POST,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

export const ACTION_TYPES = {
  SERVICE,
  FETCH,
  SET,
  RESET,
};
