import LOGIN_ROUTES from '@spot-spotview/modules/login/constants/loginRoutes.constant';
import DASHBOARD_ROUTES from '@spot-spotview/modules/dashboard/constants/dashboardRoutes.constant';
import REPORTS_ROUTES from '@spot-spotview/modules/reports/constants/reportsRoutes.constant';
import OPERATIONS_ROUTES from '@spot-spotview/modules/operations/constants/operationsRoutes.constant';
import PROFILE_ROUTES from '@spot-spotview/modules/profile/constants/profileRoutes.constant';
import POLYGONS_ROUTES from '@spot-spotview/modules/polygons/constants/polygonsRoutes.constant';
import BLOCKCHAIN_ROUTES from '@spot-spotview/modules/blockchain/constants/blockchainRoutes.constant';

const NAMESPACE = '/';
const NAMESPACE_LOGIN = LOGIN_ROUTES.ROOT;
const NAMESPACE_DASHBOARD = DASHBOARD_ROUTES.ROOT;
const NAMESPACE_REPORTS = REPORTS_ROUTES.ROOT;
const NAMESPACE_OPERATIONS = OPERATIONS_ROUTES.ROOT;
const NAMESPACE_PROFILE = PROFILE_ROUTES.ROOT;
const NAMESPACE_POLYGONS = POLYGONS_ROUTES.ROOT;
const NAMESPACE_BLOCKCHAIN = BLOCKCHAIN_ROUTES.ROOT;

export const NAMESPACES = {
  ROOT: NAMESPACE,
  LOGIN: NAMESPACE_LOGIN,
  DASHBOARD: NAMESPACE_DASHBOARD,
  REPORTS: NAMESPACE_REPORTS,
  OPERATIONS: NAMESPACE_OPERATIONS,
  PROFILE: NAMESPACE_PROFILE,
  POLYGONS: NAMESPACE_POLYGONS,
  BLOCKCHAIN: NAMESPACE_BLOCKCHAIN,
};

const ROUTES = {
  ROOT: NAMESPACE,
  LOGIN: LOGIN_ROUTES,
  DASHBOARD: DASHBOARD_ROUTES,
  REPORTS: REPORTS_ROUTES,
  PROFILE: PROFILE_ROUTES,
  OPERATIONS: OPERATIONS_ROUTES,
  BLOCKCHAIN: BLOCKCHAIN_ROUTES,
  POLYGONS: POLYGONS_ROUTES,
};

export default ROUTES;
