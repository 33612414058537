import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiReportsRegisterblockchainByIdService(
  id: number
) {
  const url = `reports/register-blockchain/${id}`;

  return fetch(url, {
    method: 'GET',
    ms: 'REPORT',
    auth: true,
  });
}
