import { all } from 'redux-saga/effects';

import polygons from './polygons';
import totals from './totals';
import polygon from './polygon';

function* watch() {
  yield all([polygons.saga.watch(), totals.saga.watch(), polygon.saga.watch()]);
}

export default {
  watch,
};
