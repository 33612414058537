import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  operation_code: string;
};

export default async function getApiPolygonsExportService(filters: Filters) {
  const url = 'polygons/download';

  return fetch(url, {
    method: 'GET',
    ms: 'POLYGONS',
    auth: true,
  });
}
