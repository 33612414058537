import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function getBlockchainService(hash) {
  const url = `https://sh4sq91klf.execute-api.sa-east-1.amazonaws.com/documents/${hash}`;

  const request = await window.fetch(url, {
    method: 'GET',
  });

  const result = await request.json();

  return [request.ok, result];
}
