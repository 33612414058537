import flow from 'lodash/flow';
import { takeLatest, call, put } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';

import getApiPolygonsById from '@spot/shared-services/polygons/getApiPolygonsById.service';

import currentState from '.';

const handleServiceGetId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiPolygonsById(action.payload.polygon_id)
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );
}

export default {
  watch,
};
