export const NAME = 'reports';

const CONTEXT = 'REPORTS';

const SERVICE_GET_ALL = `${CONTEXT}::SERVICE::GET::ALL`;

const RESET = `${CONTEXT}::RESET`;

const CHANGE = `${CONTEXT}::CHANGE`;
const MOUNT = `${CONTEXT}::MOUNT`;
const UNMOUNT = `${CONTEXT}::UNMOUNT`;

const SERVICE = {
  GET_ALL: SERVICE_GET_ALL,
};

export const ACTION_TYPES = {
  RESET,
  CHANGE,
  MOUNT,
  UNMOUNT,
  SERVICE,
};
