import reducer from './accounts.reducer';
import * as constant from './accounts.constant';
import * as action from './accounts.action';
import * as selector from './accounts.selector';
import saga from './accounts.saga';
import initialState from './accounts.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
