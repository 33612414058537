import React, { Fragment, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import get from 'lodash/get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

const TableComponent = ({
  columns,
  data,
  name,
  pagination = true,
  component = Paper,
  loading,
  metadata,
  handleUpdateFilters,
}: {
  columns: Array<{ label?: string; name: string; render?: any }>;
  data: Array<any>;
  name: string;
  pagination?: boolean;
  component?: any;
  loading?: boolean;
  metadata?: any;
  handleUpdateFilters?: any;
}) => {
  const handleChangePage = (ev, page) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    handleUpdateFilters({
      offset: (page - 1) * metadata.limit,
    });
  };

  const table = (
    <TableContainer component={component === false ? Box : Paper}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            {(Object.values(columns) || []).map((c, index) => (
              <TableCell key={`${name}-${c.name}-${index}`}>
                <Typography
                  color="secondary.main"
                  fontSize="14px"
                  fontWeight={700}
                >
                  {c.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((row, index) => (
            <TableRow
              key={`${name}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {(Object.values(columns) || []).map((c: any, idx) => (
                <Fragment key={c.name}>
                  {c?.render && <TableCell>{c.render(row)}</TableCell>}

                  {!c?.render && (
                    <TableCell>
                      <Typography color="gray.400" variant="subtitle2">
                        {get(row, c.name)}
                      </Typography>
                    </TableCell>
                  )}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination && (
        <Box alignSelf="flex-end">
          <Pagination
            count={
              Math.ceil(Number(metadata?.count) / Number(metadata?.limit)) || 0
            }
            page={(Number(metadata?.offset) / Number(metadata?.limit) || 0) + 1}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            sx={{
              ul: { justifyContent: 'end', py: 4 },
              'button.Mui-selected': { color: 'white' },
              button: { fontSize: '14px', color: 'secondary.main' },
            }}
          />
        </Box>
      )}
    </TableContainer>
  );

  return (
    <Box position="relative">
      {table}

      {loading && (
        <Box
          bgcolor="background.default"
          position="absolute"
          top={0}
          right={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default TableComponent;
