import reducer from './dialogDeleteProfile.reducer';
import * as action from './dialogDeleteProfile.action';
import * as selector from './dialogDeleteProfile.selector';
import * as constant from './dialogDeleteProfile.constant';
import initialState from './dialogDeleteProfile.initialState';
import subscriber from './dialogDeleteProfile.subscriber';
import saga from './dialogDeleteProfile.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
