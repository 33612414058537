import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import POLYGON_ROUTES from './constants/polygonsRoutes.constant';

import useProtectedByAuth from '@spot-spotview/hooks/useProtectedByAuth.hook';

import PolygonsScreen from './screens/Polygons.screen';
import PolygonsIdScreen from './screens/PolygonsId.screen';

const PolygonsModule = () => {
  useProtectedByAuth();

  return (
    <>
      <Switch>
        <Route exact path={POLYGON_ROUTES.ROOT} component={PolygonsScreen} />
        <Route exact path={POLYGON_ROUTES.ID()} component={PolygonsIdScreen} />
      </Switch>
    </>
  );
};

export default PolygonsModule;
