import React, { useRef, useEffect } from 'react';

const Canvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current as any;
    const ctx = canvas.getContext('2d');
    const myPoints = props.polygon.data.geometry.coordinates[0];
    let minX, minY, maxX, maxY;
    myPoints?.forEach((p, i) => {
      console.log('p', p);
      console.log('i', i);
      if (i === 0) {
        minX = maxX = p[0];
        minY = maxY = p[1];
      } else {
        minX = Math.min(p[0], minX);
        minY = Math.min(p[1], minY);
        maxX = Math.max(p[0], maxX);
        maxY = Math.max(p[1], maxY);
      }
    });
    const mapWidth = maxX - minX;
    const mapHeight = maxY - minY;
    const mapCenterX = (maxX + minX) / 2;
    const mapCenterY = (maxY + minY) / 2;
    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);
    ctx.beginPath();
    myPoints?.forEach((p) => {
      ctx.lineTo(
        (p[0] - mapCenterX) * scale + canvas.width / 2,
        (p[1] - mapCenterY) * scale + canvas.height / 2
      );
    });
    ctx.strokeStyle = 'rgba(156, 161, 170, 1)';
    ctx.stroke();
  }, []);

  return <canvas ref={canvasRef} {...props} />;
};

export default Canvas;
