import { isEqual } from 'lodash';
import flow from 'lodash/flow';
import { takeLatest, put, take, select } from 'redux-saga/effects';

import accounts from '../../accounts';

import currentState from '.';

function* handleRelationDelete() {
  const payload = yield select(currentState.selector.selectPayload);

  const { account_id, profile_id } = payload || {};

  const handlers = {
    serviceDelete: flow(accounts.action.serviceDeleteRelation, put),
    serviceGet: flow(accounts.action.serviceGet, put),
    close: flow(currentState.action.close, put),
  };

  console.log(payload);

  yield handlers.serviceDelete({
    account_id: account_id,
    profile_id: profile_id,
  });

  while (true) {
    const success = accounts.constant.ACTION_TYPES.FETCH.END;
    const action = yield take([success]);

    const { type } = action;

    if (type === success) {
      yield handlers.serviceGet();

      return yield handlers.close();
    }
  }
}

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.RELATION.DELETE,
    handleRelationDelete
  );
}

export default {
  watch,
};
