export const NAME = 'polygons';

const CONTEXT = 'POLYGONS::POLYGONS';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;
const SERVICE_GET_ID = `${CONTEXT}::SERVICE::GET_ID`;
const SERVICE_GET_TOTALS = `${CONTEXT}::SERVICE::GET_TOTAL`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;

const SERVICE = {
  GET: SERVICE_GET,
  GET_ID: SERVICE_GET_ID,
  GET_TOTALS: SERVICE_GET_TOTALS,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  SERVICE,
};
