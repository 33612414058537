import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  trading_name: string;
  company_name: string;
  billing_day: string;
  document_number: string;
  address_zip_code: string;
  address_street: string;
  address_number: string;
  address_complement: string;
  address_neighborhood: string;
  address_city: string;
  address_state: string;
  address_receipt_path: string;
  phone_prefix: number;
  phone_number: number;
  type: string;
  application_id: string;
  datasource_id: string;
  api_key: string;
  sponsor: string;
  sponsor_email: string;
  sponsor_phone: string;
  sponsor_ramal: string;
};

export default async function postApiCompaniesService(payload: Payload) {
  const url = ['companies'].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
