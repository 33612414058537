import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset: string;
  limit: string;
  culture_id: string;
  document_number: string;
  start_created_at: string;
  end_created_at: string;
  only_bookmark: boolean;
  productor: string;
  alert_type: string;
};

const today = new Date();

export default async function getApiOperationsService(filters: Filters) {
  const newFilters = {
    ...filters,
    start_created_at: filters?.start_created_at || new Date('2020-01-01'), // CORRIGIR ISSO AQUI
    // start_created_at:
    //   filters?.start_created_at ||
    //   new Date(today.setMonth(today.getMonth() - 13)),
    end_created_at: filters?.end_created_at || new Date(),
  };

  const filtersStringfy = qs.stringify(newFilters);

  const url = ['operations', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
  });
}
