import { combineReducers } from 'redux';

import filters from './filters';
import domain from './domain';
import alerts from './alerts';
import details from './details';
import totals from './totals';
import summary from './summary';

const reducer = combineReducers({
  [summary.name]: summary.reducer,
  [totals.name]: totals.reducer,
  [details.name]: details.reducer,
  [alerts.name]: alerts.reducer,
  [domain.name]: domain.reducer,
  [filters.name]: filters.reducer,
});

export default reducer;
