import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Dialog,
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import flow from 'lodash/flow';

import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AppLayout from '@spot-spotview/layouts/App.layout';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import ChartLineComponent from '@spot-spotview/components/ChartLine.component';
import polygonsStore from '../store/polygons';
import polygonStore from '../store/polygon';
import { useParams } from 'react-router-dom';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const PolygonsIdScreen = () => {
  const [showDate, setShowDate] = React.useState(false);
  const params = useParams<any>();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataPolygons: useSelector(polygonsStore.selector.selectData),
    dataPolygonsMetadata: useSelector(
      polygonsStore.selector.selectDataMetadata
    ),
  };

  const dispatchRedux = {
    serviceGetAll: flow(polygonsStore.action.serviceGet, dispatch),
    serviceGetById: flow(polygonStore.action.serviceGetId, dispatch),
  };

  useEffect(() => {
    dispatchRedux.serviceGetAll({ filters: { limit: 8 } });
  }, []);

  useEffect(() => {
    params.id && dispatchRedux.serviceGetById({ polygon_id: params.id });
  }, [params.id]);

  const map = (
    <Box>
      <Box p={'0px 32px 32px 32px'} position="relative" zIndex="1">
        <Box bgcolor="black" height="430px"></Box>
      </Box>
      <BoxWhite
        mt={-35}
        height="320px"
        display="flex"
        justifyContent="center"
        alignItems="end"
      >
        <Box display="flex" gap={1} alignItems="center">
          <img src="/img/starPolygon.png" />
          <Typography align="center" variant="subtitle2" color="gray.400">
            <strong>Dica:</strong> Passe o mouse por cima do polígono para mais
            informações. Clique no polígono para ver detalhes do satélite e
            meteorologia.
          </Typography>
        </Box>
      </BoxWhite>
    </Box>
  );

  const nvdi = (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Box>
              <img src="/img/nvdi1.png" />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography color="gray.300" variant="bodyxs">
                Histórico
              </Typography>
              <Typography
                color="secondary.main"
                fontSize="20px"
                fontWeight={600}
              >
                NVDI
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={2}>
            <TextField
              size="small"
              label="Mês"
              onClick={() => setShowDate(true)}
              InputProps={{
                endAdornment: (
                  <CalendarMonthIcon sx={{ color: 'secondary.main' }} />
                ),
              }}
            />
            <TextField
              size="small"
              label="Mês"
              onClick={() => setShowDate(true)}
              InputProps={{
                endAdornment: (
                  <CalendarMonthIcon sx={{ color: 'secondary.main' }} />
                ),
              }}
            />
          </Box>
        </Box>
        <Box height={300}>
          <ChartLineComponent />
        </Box>
      </Box>
    </BoxWhite>
  );

  const polygons = (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={1} alignItems="center">
          <BuildTwoToneIcon color="primary" />
          <Typography color="secondary.main" fontSize="16px" fontWeight={700}>
            Polígonos
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          {selectorRedux.dataPolygons?.results?.map((polygon, idx) => (
            <React.Fragment key={`polygon-${idx}`}>
              {idx !== 0 && <Divider />}

              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2" color="gray.400">
                    {polygon.code}
                  </Typography>
                  <Typography
                    fontWeight={700}
                    variant="subtitle2"
                    color="gray.400"
                  >
                    {polygon.area}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </BoxWhite>
  );

  return (
    <AppLayout title="Polígonos">
      <Box display="flex" flexDirection="column" gap={3}>
        {map}
        <Box display="grid" gridTemplateColumns="1fr 250px" gap={3}>
          {nvdi}
          {polygons}
        </Box>
      </Box>

      <Dialog open={showDate} onClose={() => setShowDate(false)}>
        <DateRange
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="MMMM yyyy"
          locale={ptBR}
          showDateDisplay={false}
          months={2}
          direction="horizontal"
          ranges={[
            {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            },
          ]}
          onChange={({ selection }) => {}}
        />
      </Dialog>
    </AppLayout>
  );
};

export default PolygonsIdScreen;
