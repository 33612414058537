export const NAME = 'dialogDeleteAccount';

const CONTEXT = 'PROFILE::DIALOGS::DELETE_ACCOUNT';

const ACCOUNT_DELETE = `${CONTEXT}::ACCOUNT::DELETE`;

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const ACCOUNT = {
  DELETE: ACCOUNT_DELETE,
};

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

export const ACTION_TYPES = {
  SET,
  RESET,
  ACCOUNT,
};
