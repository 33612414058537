import reducer from './operations.reducer';
import * as selector from './operations.selector';
import * as constant from './operations.constant';
import * as action from './operations.action';
import subscriber from './operations.subscriber';
import saga from './operations.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
