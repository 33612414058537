import { all } from 'redux-saga/effects';

import domain from './domain';
import alerts from './alerts';
import details from './details';
import totals from './totals';
import summary from './summary';
import filters from './filters';

function* watch() {
  yield all([
    filters.saga.watch(),
    summary.saga.watch(),
    totals.saga.watch(),
    details.saga.watch(),
    alerts.saga.watch(),
    domain.saga.watch(),
  ]);
}

export default {
  watch,
};
