import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import misc from '@spot-spotview/store/configs/misc';

const BoxGrayComponent = ({ children }) => {
  const selectorRedux = {
    mode: useSelector(misc.selector.selectMode),
  };

  const { mode } = selectorRedux;

  return (
    <Box
      borderRadius={1}
      p={2}
      bgcolor={mode === 'dark' ? 'background.default' : '#F8F9FA'}
      boxShadow="0px 0px 10px #0000000f"
      sx={{
        '@media print': {
          boxShadow: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default BoxGrayComponent;
