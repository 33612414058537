import { useEffect } from 'react';
import { orderBy } from 'lodash';
import { Box, Typography } from '@mui/material';
import flow from 'lodash/flow';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { LoadingButton as Button } from '@mui/lab';

import dialogInviteAccount from '../store/dialogs/dialogInviteAccount';

import DialogBaseComponent from '@spot-spotview/components/DialogBase.component';
import FormArrFormikComponent from '@spot-spotview/components/FormArrFormik.component';

import ThemeProvider from '@spot-spotview/providers/Theme.provider';
import profiles from '../store/profiles';

export default function DialogInviteAccount() {
  const dispatch = useDispatch();

  const open = useSelector(dialogInviteAccount.selector.selectVisible);
  const loading = useSelector(dialogInviteAccount.selector.selectLoading);
  const dataProfiles = useSelector(profiles.selector.selectData);

  const handleClose = flow(dialogInviteAccount.action.close, dispatch);
  const servicePost = flow(dialogInviteAccount.action.servicePost, dispatch);
  const serviceGet = flow(profiles.action.serviceGet, dispatch);

  useEffect(() => {
    if (open) {
      serviceGet({ filters: { limit: 99999 } });
    }
  }, [open]);

  return (
    <ThemeProvider>
      <DialogBaseComponent visible={open} handleClose={handleClose}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box>
              <img src="/img/inviteuser.png" style={{ display: 'block' }} />
            </Box>
            <Typography variant="h3" color="secondary.main">
              Convidar usuários
            </Typography>
            <Typography variant="subtitle1" color="gray.500">
              Informe abaixo os e-mails dos usuários que deseja convidar,
              separados por vírgula.
            </Typography>
          </Box>

          <Box>
            <Formik
              initialValues={{
                emails: '',
              }}
              onSubmit={async (values) => {
                servicePost(values);

                return true;
              }}
            >
              {() => (
                <Form>
                  <FormArrFormikComponent
                    boxProps={{
                      gap: 4,
                    }}
                    fields={[
                      [
                        {
                          label: 'Perfil',
                          name: 'profile_id',
                          options: [
                            {},
                            ...orderBy(dataProfiles?.results, 'description'),
                          ].map((c) => ({
                            label: c.description,
                            value: c.id,
                          })),
                          textFieldProps: {
                            select: true,
                            SelectProps: {
                              native: true,
                            },
                          },
                        },
                      ],
                      [
                        {
                          label: 'Emails dos usuários',
                          name: 'emails',
                          textFieldProps: {
                            helperText:
                              'adicione os emails separados por vírgula',
                            multiline: true,
                            rows: 5,
                          },
                        },
                      ],
                    ]}
                  />

                  <Box display="flex" justifyContent="end" gap={4} mt={3}>
                    <Button size="large" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button
                      size="large"
                      // onClick={handleClose}
                      variant="contained"
                      sx={{ color: 'white' }}
                      type="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      Enviar
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </DialogBaseComponent>
    </ThemeProvider>
  );
}
