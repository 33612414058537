import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';

import toaster from '@spot/shared-store/toaster';

import getApiDashboardsAlertsService from '@spot/shared-services/dashboard/getApiDashboardsAlerts.service';

import { ACTION_TYPES } from '../dashboard.constant';

import filters from '../filters';

import currentState from '.';

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(filters.selector.selectState),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      getApiDashboardsAlertsService as any,
      omit(pickBy(seletors.filters), 'offset', 'limit')
    );

    if (!success) {
      throw result;
    }

    const parserData = result.map((r) => ({
      ...r,
      count: Number(r.count),
    }));

    return yield handlers.fetchEnd(parserData);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    filters.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );

  yield takeLatest(ACTION_TYPES.SERVICE.GET_ALL, handleServiceGet);
}

export default {
  watch,
};
