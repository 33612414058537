import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export async function getOpenMeteoService({ lat, lng, begin_date, end_date }) {
  const newFilters = qs.stringify(
    {
      latitude: lat,
      longitude: lng,
      start_date: begin_date,
      end_date: end_date,
      // models: 'era5_land',
      // models: 'best_match',
      daily: 'weathercode,temperature_2m_mean,precipitation_sum',
      // hourly: 'temperature_2m,precipitation,rain',
      timezone: 'America/Sao_Paulo',
    },
    {
      encode: false,
    }
  );

  const url = `https://archive-api.open-meteo.com/v1/archive?${newFilters}`;

  const request = await window.fetch(url, {
    method: 'GET',
  });

  const result = await request.json();

  return [request.ok, result];
}
