import React, { useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';

import PROFILE_ROUTES from './constants/profileRoutes.constant';

import useProtectedByAuth from '@spot-spotview/hooks/useProtectedByAuth.hook';

import ProfileScreen from './screens/Profile.screen';
import ProfileEditUserScreen from './screens/ProfileEditUser.screen';
import ProfileCreateEditCompanyComponent from './screens/ProfileCreateEditCompany.screen';
import ProfileCreateEditProfileComponent from './screens/ProfileCreateEditProfile.screen';

import DialogInviteUser from './components/DialogInviteAccount.component';
import DialogDeleteAccount from './components/DialogDeleteAccount.component';
import DialogDeleteProfile from './components/DialogDeleteProfile.component';
import DialogDeleteRelation from './components/DialogDeleteRelation.component';

import config from './store/config';

const ProfileModule = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const dispatchRedux = {
    resetState: flow(config.action.resetState, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.resetState();
  }, []);

  return (
    <Fragment>
      <Switch>
        <Route exact path={PROFILE_ROUTES.ROOT} component={ProfileScreen} />
        <Route
          exact
          path={PROFILE_ROUTES.EDIT_USER()}
          component={ProfileEditUserScreen}
        />
        <Route
          exact
          path={PROFILE_ROUTES.EDIT_COMPANY()}
          component={ProfileCreateEditCompanyComponent}
        />
        <Route
          exact
          path={PROFILE_ROUTES.CREATE_COMPANY}
          component={ProfileCreateEditCompanyComponent}
        />
        <Route
          exact
          path={PROFILE_ROUTES.EDIT_PROFILE()}
          component={ProfileCreateEditProfileComponent}
        />
        <Route
          exact
          path={PROFILE_ROUTES.CREATE_PROFILE}
          component={ProfileCreateEditProfileComponent}
        />
      </Switch>

      <DialogInviteUser />
      <DialogDeleteAccount />
      <DialogDeleteProfile />
      <DialogDeleteRelation />
    </Fragment>
  );
};

export default ProfileModule;
