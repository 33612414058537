import { Box, Divider, Typography, Tabs, Tab, Button } from '@mui/material';

const BoxOperationComponent = ({ label, amount, ...restProps }) => {
  return (
    <Box display="flex" flexDirection="column" {...restProps}>
      <Typography variant="subtitle2" color="gray.300">
        {label}
      </Typography>
      <Typography variant="subtitle1" color="gray.black" fontWeight={500}>
        {amount}
      </Typography>
    </Box>
  );
};

export default BoxOperationComponent;
