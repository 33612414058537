import React from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadingButton as Button } from '@mui/lab';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import {
  Box,
  Divider,
  Typography,
  Tabs,
  Tab,
  Avatar,
  IconButton,
  TextField,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import format from 'date-fns/format';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import toCurrencyUtil from '@spot-spotview/utils/toCurrency.util';
import toDateHourUtil from '@spot-spotview/utils/toDateHour.util';
import toDecimalUtil from '@spot-spotview/utils/toDecimal.util';
import toNumberUtil from '@spot-spotview/utils/toNumber.util';

import messages from '@spot/shared-constants/messagesSchema.constant';

import FieldUploadPhoto from '@spot-spotview/components/FieldUpload.component';

import AppLayout from '@spot-spotview/layouts/App.layout';

import FormArrFormikComponent from '@spot-spotview/components/FormArrFormik.component';
import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import TableComponent from '@spot-spotview/components/Table.component';

import useAuth from '@spot/shared-hooks/useAuth.hook';

import PROFILE_ROUTES from '../constants/profileRoutes.constant';

import user from '../store/currentUser';
import profiles from '../store/profiles';
import invoices from '../store/invoices';
import config from '../store/config';
import accounts from '../store/accounts';
import companies from '../store/companies';
import dialogDeleteAccount from '../store/dialogs/dialogDeleteAccount';
import dialogInviteAccount from '../store/dialogs/dialogInviteAccount';
import dialogDeleteProfile from '../store/dialogs/dialogDeleteProfile';

const BoxInvoice = ({ label, amount, currency = false }) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="subtitle2" color="gray.300">
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={currency ? 700 : 500}
        color="secondary.main"
        sx={{
          padding: currency ? '4px 8px' : undefined,
          bgcolor: currency ? 'info.light3' : undefined,
          borderRadius: 0.5,
          alignSelf: 'start',
        }}
      >
        {amount}
      </Typography>
    </Box>
  );
};

const ProfileScreen = () => {
  const [invoiceMonth, setInvoiceMonth] = React.useState(new Date());
  const [hasPhoto, setHasPhoto] = React.useState<any>(false);
  const { user: userToken } = useAuth();

  const history = useHistory();

  const dispatch = useDispatch();

  const selectorRedux = {
    dataUser: useSelector(user.selector.selectData),
    dataUsers: useSelector(accounts.selector.selectData),
    dataResultsUsers: useSelector(accounts.selector.selectDataResults),
    dataMetadataUsers: useSelector(accounts.selector.selectDataMetadata),
    dataClients: useSelector(companies.selector.selectData),
    dataResultsClients: useSelector(companies.selector.selectDataResults),
    dataMetadataClients: useSelector(companies.selector.selectDataMetadata),
    dataInvoices: useSelector(invoices.selector.selectData),
    dataProfiles: useSelector(profiles.selector.selectData),
    dataResultProfiles: useSelector(profiles.selector.selectDataResults),
    dataMetadataProfiles: useSelector(profiles.selector.selectDataMetadata),
    loadingUser: useSelector(user.selector.selectLoading),
    loadingUsers: useSelector(accounts.selector.selectLoading),
    loadingClients: useSelector(companies.selector.selectLoading),
    loadingInvoices: useSelector(invoices.selector.selectLoading),
    loadingProfiles: useSelector(profiles.selector.selectLoading),
    tab: useSelector(config.selector.selectTab),
  };

  const dispatchRedux = {
    open: flow(dialogInviteAccount.action.open, dispatch),
    openDelete: flow(dialogDeleteAccount.action.open, dispatch),
    openDeleteProfile: flow(dialogDeleteProfile.action.open, dispatch),
    resetStateUsers: flow(accounts.action.resetState, dispatch),
    serviceGetUsers: flow(accounts.action.serviceGet, dispatch),
    serviceGetProfiles: flow(profiles.action.serviceGet, dispatch),
    updateFiltersUsers: flow(accounts.action.updateFilters, dispatch),
    updateFiltersProfiles: flow(profiles.action.updateFilters, dispatch),
    updateFiltersClients: flow(companies.action.updateFilters, dispatch),
    pdateFiltersProfiles: flow(profiles.action.updateFilters, dispatch),
    serviceGetClients: flow(companies.action.serviceGet, dispatch),
    serviceGetInvoices: flow(invoices.action.serviceGet, dispatch),
    updateFilters: flow(invoices.action.updateFilters, dispatch),
    servicePatchUser: flow(user.action.servicePatch, dispatch),
    updateTab: flow(config.action.updateTab, dispatch),
    resetState: flow(config.action.resetState, dispatch),
  };

  const isStaff = userToken?.user?.is_staff;
  const isAdmin = userToken?.user?.is_admin;

  const currentTab = selectorRedux.tab;
  const setCurrentTab = flow(dispatchRedux.updateTab);

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetStateUsers();
    };
  }, []);

  React.useEffect(() => {
    if (currentTab === 2) {
      dispatchRedux.serviceGetUsers();
    }

    if (currentTab === 3) {
      dispatchRedux.serviceGetClients();
    }

    if (currentTab === 4) {
      dispatchRedux.serviceGetInvoices();
    }

    if (currentTab === 6) {
      dispatchRedux.serviceGetProfiles({ filters: { limit: 20 } });
    }
  }, [currentTab]);

  React.useEffect(() => {
    if (selectorRedux.dataUser?.photo) {
      setHasPhoto(selectorRedux.dataUser?.photo);
    }
  }, [selectorRedux.dataUser]);

  const profile = (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        nome: selectorRedux.dataUser?.name,
        email: selectorRedux.dataUser?.email,
        senha: '',
        confirmasenha: '',
      }}
      onSubmit={async (values) => {
        dispatchRedux.servicePatchUser(values);

        return true;
      }}
      validationSchema={Yup.object({
        email: Yup.string().email(messages.EMAIL),
        senha: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            messages.PASSWORD_SPECS
          )
          .oneOf([Yup.ref('confirmasenha'), ''], 'As senhas não são iguais'),
        confirmasenha: Yup.string().oneOf(
          [Yup.ref('senha'), ''],
          'As senhas não são iguais'
        ),
      })}
    >
      {({ submitForm, errors, values, setFieldValue }) => (
        <Form key={`profile-form-1-${selectorRedux.dataUser}`}>
          <Box
            display="grid"
            gridTemplateColumns="300px 1fr"
            gap={4}
            alignItems="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              borderRadius="6px"
            >
              {hasPhoto && (
                <Box display="flex" flexDirection="column">
                  <img src={hasPhoto} style={{ maxWidth: '100%' }} />

                  <Button
                    onClick={() => {
                      setHasPhoto(false);
                      setFieldValue('empty_photo', true);
                    }}
                  >
                    Remover foto
                  </Button>
                </Box>
              )}

              {!hasPhoto && (
                <FieldUploadPhoto
                  name="photo"
                  onDrop={() => {
                    setFieldValue('empty_photo', false);
                  }}
                />
              )}
            </Box>

            <Box display="flex" flexDirection="column" gap={4}>
              <FormArrFormikComponent
                boxProps={{
                  gap: 4,
                }}
                fields={[
                  [
                    {
                      label: 'Nome',
                      name: 'nome',
                    },
                  ],
                  [
                    {
                      label: 'E-mail',
                      name: 'email',
                    },
                  ],
                  [
                    {
                      label: 'Senha',
                      name: 'senha',
                      variant: 'password',
                    },
                    {
                      label: 'Repetir senha',
                      name: 'confirmasenha',
                      variant: 'password',
                    },
                  ],
                ]}
              />

              <Box alignSelf="end">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  sx={{ color: 'white' }}
                  type="submit"
                  loading={selectorRedux.loadingUser}
                  disabled={
                    Object.keys(errors).length > 0 || selectorRedux.loadingUser
                  }
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );

  const users = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="18px" fontWeight={700} color="secondary.main">
          Usuários cadastrados
        </Typography>
        <Button
          variant="contained"
          sx={{ bgcolor: 'info.main', color: 'white' }}
          onClick={() => dispatchRedux.open('')}
        >
          Convidar usuário
        </Button>
      </Box>

      <Box>
        <TableComponent
          name="account"
          component={false}
          loading={selectorRedux.loadingUsers}
          data={selectorRedux?.dataResultsUsers || []}
          metadata={selectorRedux.dataMetadataUsers?.resultset}
          handleUpdateFilters={dispatchRedux.updateFiltersUsers}
          columns={[
            {
              label: 'Nome',
              name: 'name',
              render: (props) => (
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar src={props?.photo} sx={{ width: 32, height: 32 }} />
                  <Typography variant="subtitle2" color="gray.400">
                    {props.name}
                  </Typography>
                </Box>
              ),
            },
            { label: 'Email', name: 'email' },
            {
              label: 'Último login',
              name: 'last_login',
              render: ({ last_login }) => (
                <Typography color="gray.400" variant="subtitle2">
                  {toDateHourUtil(last_login)}
                </Typography>
              ),
            },
            {
              name: 'actions',
              render: (props) => {
                const { id } = props;

                const isCurrentUser = id === userToken?.user?.id;

                return (
                  <Box display="flex">
                    <IconButton
                      sx={{ color: 'info.light' }}
                      onClick={() => {
                        if (isCurrentUser) {
                          setCurrentTab(1);
                        } else {
                          history.push(PROFILE_ROUTES.EDIT_USER(props.id));
                        }
                      }}
                    >
                      <BorderColorIcon />
                    </IconButton>
                    {!isCurrentUser && (
                      <IconButton
                        sx={{ color: 'error.main' }}
                        onClick={() =>
                          dispatchRedux.openDelete({
                            id: props.id,
                            email: props.email,
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                );
              },
            },
          ]}
        />
      </Box>
    </Box>
  );

  const profilesTab = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="18px" fontWeight={700} color="secondary.main">
          Perfis
        </Typography>
        <Button
          variant="contained"
          sx={{ bgcolor: 'info.main', color: 'white' }}
          onClick={() => history.push(PROFILE_ROUTES.CREATE_PROFILE)}
        >
          Novo perfil
        </Button>
      </Box>
      <Box>
        <TableComponent
          name="profiles"
          component={false}
          pagination={true}
          metadata={selectorRedux?.dataMetadataProfiles?.resultset}
          data={selectorRedux?.dataProfiles?.results || []}
          handleUpdateFilters={dispatchRedux.updateFiltersProfiles}
          loading={selectorRedux.loadingClients}
          columns={[
            {
              label: 'Status',
              name: 'active',
              render: (props) => (
                <Box display="flex" alignItems="center" gap={2}>
                  <Box
                    width={8}
                    height={8}
                    bgcolor="primary.main"
                    borderRadius="50%"
                  />
                  <Typography variant="subtitle2" color="primary.main">
                    {props.active ? 'Ativo' : 'Inativo'}
                  </Typography>
                </Box>
              ),
            },
            { label: 'Perfil', name: 'description' },
            {
              name: 'actions',
              render: (props) => (
                <Box display="flex">
                  <Button
                    startIcon={<BorderColorIcon />}
                    sx={{ color: 'info.light' }}
                    onClick={() =>
                      history.push(PROFILE_ROUTES.EDIT_PROFILE(props.id))
                    }
                  ></Button>
                  <IconButton
                    sx={{ color: 'error.main' }}
                    onClick={() =>
                      dispatchRedux.openDeleteProfile({
                        id: props.id,
                        description: props.description,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );

  const company = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="18px" fontWeight={700} color="secondary.main">
          Empresas/Clientes
        </Typography>
        <Button
          variant="contained"
          sx={{ bgcolor: 'info.main', color: 'white' }}
          onClick={() => history.push(PROFILE_ROUTES.CREATE_COMPANY)}
        >
          Nova empresa
        </Button>
      </Box>
      <Box>
        <TableComponent
          name="clients"
          component={false}
          pagination={true}
          metadata={selectorRedux?.dataMetadataClients?.resultset}
          data={selectorRedux?.dataClients?.results || []}
          handleUpdateFilters={dispatchRedux.updateFiltersClients}
          loading={selectorRedux.loadingClients}
          columns={[
            {
              label: 'Status',
              name: 'active',
              render: (props) => (
                <Box display="flex" alignItems="center" gap={2}>
                  <Box
                    width={8}
                    height={8}
                    bgcolor="primary.main"
                    borderRadius="50%"
                  />
                  <Typography variant="subtitle2" color="primary.main">
                    {props.active ? 'Ativo' : 'Inativo'}
                  </Typography>
                </Box>
              ),
            },
            { label: 'Nome', name: 'name' },
            { label: 'CNPJ', name: 'document_number' },
            {
              label: 'Criado em',
              name: 'created_at',
              render: ({ created_at }) => (
                <Typography color="gray.400" variant="subtitle2">
                  {toDateHourUtil(created_at)}
                </Typography>
              ),
            },
            {
              name: 'actions',
              render: ({ id }) => (
                <Box display="flex" justifyContent="space-around">
                  <Button
                    startIcon={<BorderColorIcon />}
                    sx={{ color: 'info.light' }}
                    onClick={() =>
                      history.push(PROFILE_ROUTES.EDIT_COMPANY(id))
                    }
                  >
                    Editar
                  </Button>
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );

  const invoice = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="18px" fontWeight={700} color="secondary.main">
          {selectorRedux.dataInvoices?.client_info}
        </Typography>
        <Box>
          <DatePicker
            locale={ptBR}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selected={invoiceMonth}
            onChange={(date) => {
              setInvoiceMonth(date);

              const month = format(new Date(date), 'MM');
              const year = format(new Date(date), 'yyyy');

              dispatchRedux.updateFilters({
                month,
                year,
              });
            }}
            customInput={
              <TextField
                label="Mês"
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            }
          />
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns="1fr 30%" gap={9}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Requisições
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
              {selectorRedux.dataInvoices?.requests?.map((r) => (
                <BoxInvoice
                  key={r?.api_name}
                  label={r?.api_name}
                  amount={toNumberUtil(r?.count)}
                />
              ))}
              <BoxInvoice
                key="Total de requisições gratuitas"
                label="Total de requisições gratuitas"
                amount={toNumberUtil(selectorRedux.dataInvoices?.free_requests)}
              />
              <BoxInvoice
                key="Total de requisições realizadas"
                label="Total de requisições realizadas"
                amount={toNumberUtil(
                  selectorRedux.dataInvoices?.total_requests
                )}
              />
              <BoxInvoice
                key="Taxa de requisições"
                label="Taxa de requisições"
                currency
                amount={toCurrencyUtil(
                  selectorRedux.dataInvoices?.tax_request_surplus
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Taxas de manutenção
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
              {selectorRedux.dataInvoices?.spents_by_client?.map((r) => (
                <BoxInvoice
                  key={r?.description}
                  label={r?.description}
                  amount={toCurrencyUtil(r?.value)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize="16px" fontWeight={700} color="secondary.main">
            Área monitorada
          </Typography>
          <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={2}>
            <BoxInvoice
              label="Área total"
              amount={`${selectorRedux.dataInvoices?.total_area} ha`}
            />
            <BoxInvoice
              label="Quantidade de polígonos"
              amount={toNumberUtil(selectorRedux.dataInvoices?.total_polygons)}
            />
            <BoxInvoice
              label="Cobrado por ha"
              currency
              amount={toCurrencyUtil(selectorRedux.dataInvoices?.plan_value)}
            />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box
        alignSelf="end"
        // bgcolor="error.light3"
        p="8px 16px"
        borderRadius={0.5}
        display="flex"
        gap={3}
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle2" color="secondary.main">
            Total
          </Typography>
          <Typography fontSize="18px" fontWeight={700}>
            {toCurrencyUtil(selectorRedux.dataInvoices?.total_bill)}
          </Typography>
        </Box>

        {/* <Box display="flex" alignItems="center" gap={0.5}>
          <WarningAmberIcon sx={{ fontSize: '16px' }} color="error" />
          <Typography variant="subtitle2" color="error.dark">
            pendente
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );

  const counter = (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="18px" fontWeight={700} color="secondary.main">
          Contador
        </Typography>
        <Box>
          <TextField label="Mês" variant="outlined" />
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns="1fr" alignItems="start" gap={4}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Operações em análise mês anterior
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BoxInvoice label="Area Fianciada" amount={'2.653,13ha'} />
              <BoxInvoice label="Área Cultivável" amount={'2.175,90ha'} />
              <BoxInvoice label="Area Informada" amount={'2.653,13ha'} />
              <BoxInvoice label="Quantidade" amount={'123'} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Operações contratadas mês corrente
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BoxInvoice label="Area Fianciada" amount={'2.653,13ha'} />
              <BoxInvoice label="Área Cultivável" amount={'2.175,90ha'} />
              <BoxInvoice label="Area Informada" amount={'2.653,13ha'} />
              <BoxInvoice label="Quantidade" amount={'123'} />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Operações em análise mês anterior
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BoxInvoice label="Area Fianciada" amount={'2.653,13ha'} />
              <BoxInvoice label="Área Cultivável" amount={'2.175,90ha'} />
              <BoxInvoice label="Area Informada" amount={'2.653,13ha'} />
              <BoxInvoice label="Quantidade" amount={'123'} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Operações contratadas mês corrente
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BoxInvoice label="Area Fianciada" amount={'2.653,13ha'} />
              <BoxInvoice label="Área Cultivável" amount={'2.175,90ha'} />
              <BoxInvoice label="Area Informada" amount={'2.653,13ha'} />
              <BoxInvoice label="Quantidade" amount={'123'} />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography fontSize="16px" fontWeight={700} color="secondary.main">
              Operações em análise mês anterior
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
              <BoxInvoice label="Area Fianciada" amount={'2.653,13ha'} />
              <BoxInvoice label="Área Cultivável" amount={'2.175,90ha'} />
              <BoxInvoice label="Area Informada" amount={'2.653,13ha'} />
              <BoxInvoice label="Quantidade" amount={'123'} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const tabs = [
    {
      label: 'CONTA',
      value: 1,
    },
    isAdmin && {
      label: 'PERFIS',
      value: 6,
    },
    isAdmin && {
      label: 'USUÁRIOS',
      value: 2,
    },
    isStaff && {
      label: 'FATURAS',
      value: 4,
    },
  ].filter((t) => !!t);

  return (
    <AppLayout title="Configurações">
      <Box display="flex" flexDirection="column" gap={3}>
        <BoxWhite
          loading={[
            selectorRedux.loadingClients,
            selectorRedux.loadingInvoices,
            selectorRedux.loadingUser,
            selectorRedux.loadingUsers,
            selectorRedux.loadingProfiles,
          ].some((l) => l)}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            <Box mx={-3} mt={-3}>
              <Tabs
                centered
                value={currentTab}
                onChange={(_, val) => setCurrentTab(val)}
                sx={{
                  button: {
                    flex: 1,
                    maxWidth: 'none',
                  },
                }}
              >
                {tabs.map((t: any) => (
                  <Tab
                    label={t.label}
                    value={t.value}
                    key={t.value}
                    disabled={t?.disabled}
                  />
                ))}
              </Tabs>
            </Box>

            {currentTab === 1 && profile}
            {currentTab === 2 && users}
            {currentTab === 3 && company}
            {currentTab === 4 && invoice}
            {currentTab === 5 && counter}
            {currentTab === 6 && profilesTab}
          </Box>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default ProfileScreen;
