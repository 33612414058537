import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  name: string;
  document_number: string;
  lat: number;
  lon: number;
  zip_code: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  sponsor: string;
  sponsor_email: string;
  sponsor_phone: string;
  sponsor_ramal: string;
  datasource_id: string;
  cli_plan_id: string;
  api_key: string;
};

export default async function postApiAuthService(payload: Payload) {
  const url = ['clients'].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'USER',
    body: JSON.stringify(payload),
    auth: true,
  });
}
