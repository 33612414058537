import { isEqual } from 'lodash';
import flow from 'lodash/flow';
import { takeLatest, put, take, select } from 'redux-saga/effects';

import profiles from '../../profiles';

import currentState from '.';

function* handleProfileDelete() {
  const payload = yield select(currentState.selector.selectPayload);

  const { id } = payload || {};

  const handlers = {
    serviceDelete: flow(profiles.action.serviceDelete, put),
    serviceGet: flow(profiles.action.serviceGet, put),
    close: flow(currentState.action.close, put),
  };

  yield handlers.serviceDelete({
    id,
  });

  while (true) {
    const success = profiles.constant.ACTION_TYPES.FETCH.END;
    const action = yield take([success]);

    const { type } = action;

    if (type === success) {
      yield handlers.serviceGet({});

      return yield handlers.close();
    }
  }
}

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.PROFILE.DELETE,
    handleProfileDelete
  );
}

export default {
  watch,
};
