import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiOperationsDatasourceService() {
  const url = 'operations/datasource';

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
  });
}
