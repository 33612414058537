import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';

import toaster from '@spot/shared-store/toaster';

import postApiAccountsIdInvitesService from '@spot/shared-services/account/postApiAccountsIdInvites.service';

import currentUser from '../../currentUser';

import currentState from '.';

const handleServicePost = function* (action) {
  const selectors = {
    dataAccount: yield select(currentUser.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    reset: flow(currentState.action.reset, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      postApiAccountsIdInvitesService,
      selectors.dataAccount.id,
      {
        profile_id: payload?.profile_id,
        emails: payload?.emails?.split(','),
      }
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário(s) convidado(s) com sucesso.',
      variant: 'success',
    });

    return yield handlers.reset();
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
}

export default {
  watch,
};
