import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiOperationsIdNdvichartService(id: string) {
  const url = ['operations', `${id}`, 'ndvi-chart'].join('/');

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
  });
}
