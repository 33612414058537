import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  month?: string;
  year?: string;
};

export default async function getApiInvoicesService(filters: Filters) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['invoices', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'BILLING',
    auth: true,
  });
}
