import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import OPERATIONS_ROUTES from './constants/operationsRoutes.constant';

import useAuth from '@spot/shared-hooks/useAuth.hook';

import useProtectedByAuth from '@spot-spotview/hooks/useProtectedByAuth.hook';
import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';

import OperationsScreen from './screens/Operations.screen';
import OperationsIdScreen from './screens/OperationsId.screen';
import OperationsIdPrintScreen from './screens/OperationsIdPrint.screen';

import * as action from './store/operations.action';

const OperationsModule = () => {
  const dispatch = useDispatch();

  useProtectedByAuth();

  const { signedIn } = useAuth();

  useEffectOnce(() => {
    if (signedIn) {
      dispatch(action.serviceGetAll());
    }
  });

  return (
    <>
      <Switch>
        <Route
          exact
          path={OPERATIONS_ROUTES.ROOT}
          component={OperationsScreen}
        />

        <Route
          exact
          path={OPERATIONS_ROUTES.ID()}
          component={OperationsIdScreen}
        />

        <Route
          exact
          path={OPERATIONS_ROUTES.ID_PRINT()}
          component={OperationsIdPrintScreen}
        />
      </Switch>
    </>
  );
};

export default OperationsModule;
