import flow from 'lodash/flow';
import { takeLatest, call, put, take } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import format from 'date-fns/format';

import toaster from '@spot/shared-store/toaster';

import getApiOperationsIdWeatherchartService from '@spot/shared-services/operation/getApiOperationsIdWeatherchart.service';
import { getOpenMeteoService } from '@spot/shared-services/openMeteo.service';

import currentState from '.';
import operation from '../operation';

const handleServiceGet = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    while (true) {
      const success = operation.constant.ACTION_TYPES.FETCH.END;
      const error = operation.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        throw payload;
      }

      if (type === success) {
        const [, resultWeather] = yield call(getOpenMeteoService as any, {
          lat: payload?.coordinates?.lat,
          lng: payload?.coordinates?.lng,
          begin_date: payload?.planting_start,
          end_date: payload?.planting_end || format(new Date(), 'YYYY-MM-DD'),
        });

        return yield handlers.fetchEnd(resultWeather);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
