import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  profile_id: string;
};

export default async function postApiAuthChangeProfileService(
  payload: Payload
) {
  const url = 'auth/active-profile';
  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
