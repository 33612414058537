import React, { useEffect, useRef } from 'react';
import { Box, Divider, Typography, Button, Switch } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import orderBy from 'lodash/orderBy';
import * as yup from 'yup';

import useEffectOnce from '@spot-spotview/hooks/useEffectOnce.hook';

import FormArrComponent from '@spot-spotview/components/FormArrFormik.component';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';

import AppLayout from '@spot-spotview/layouts/App.layout';
import useAuth from '@spot/shared-hooks/useAuth.hook';

import cooperatives from '../store/cooperatives';

import profiles from '../store/profiles';
import _ from 'lodash';
import authenticate from '../store/authenticate';
import ROUTES from '@spot-spotview/constants/routes.constant';

const ProfileCreateEditProfileScreen = () => {
  const loaded = useRef(false);

  const dispatch = useDispatch();
  const params: any = useParams();

  const history = useHistory();

  const selectorRedux = {
    data: useSelector(profiles.selector.selectData),
    loading: useSelector(profiles.selector.selectLoading),
    dataCooperatives: useSelector(cooperatives.selector.selectData),
    loadingCooperatives: useSelector(cooperatives.selector.selectLoading),
  };

  const dispatchRedux = {
    servicePost: flow(profiles.action.servicePost, dispatch),
    servicePostToken: flow(authenticate.action.servicePost, dispatch),
    servicePatch: flow(profiles.action.servicePatch, dispatch),
    serviceGetId: flow(profiles.action.serviceGetId, dispatch),
    serviceGetCooperatives: flow(cooperatives.action.serviceGet, dispatch),
    resetState: flow(profiles.action.resetState, dispatch),
  };

  const isLoading = [
    selectorRedux.loadingCooperatives,
    selectorRedux.loading,
  ].some((l) => (selectorRedux.loadingCooperatives !== null ? l : true));

  const fields = [
    [
      {
        label: '',
        value: '0',
        name: 'check-0',
        variant: 'checkbox',
        checked: false,
      },
    ],
  ];
  const isId = params?.id;

  const { token } = useAuth();

  useEffectOnce(() => {
    if (isId) {
      dispatchRedux.serviceGetId(isId);
      dispatchRedux.serviceGetCooperatives();
    }
    dispatchRedux.serviceGetCooperatives();
  });

  useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const cooperativesArray: any = !isLoading
    ? _.chunk(
        selectorRedux.dataCooperatives?.results
          ?.map((c, index) => ({
            checked: selectorRedux.data?.cooperatives?.includes(c.id),
            label: `${c.name} - ${c.document_number.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5'
            )}`,
            value: c.id,
            name: `check-${c.id}`,
            variant: 'checkbox',
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
        2
      )
    : fields;

  const title = isId ? selectorRedux.data?.name : 'Novo Perfil';

  const up = (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button
            onClick={() => {
              dispatchRedux.resetState();

              history.goBack();
            }}
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'info.main' }}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const body = (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnBlur
      validateOnChange={false}
      initialValues={Object.assign(
        {},
        {
          description: '',
        },
        isId && { ...selectorRedux.data }
      )}
      validationSchema={yup.object({
        description: yup
          .string()
          .required()
          .required('Descrição do Perfil')
          .min(5),
      })}
      onSubmit={async (values) => {
        const cooperatives_true: any = [];
        const cooperatives_false: any = [];

        for (const x of Object.keys(values)) {
          if (x.includes('check-') && values[x] === true) {
            cooperatives_true.push(x.substring(6));
          }
          if (x.includes('check-') && values[x] === false) {
            cooperatives_false.push(x.substring(6));
          }
        }
        const cooperatives =
          values.cooperatives?.filter((c) => !cooperatives_false.includes(c)) ||
          [];

        if (isId) {
          const payload = {
            id: values?.id,
            cooperatives_ids: [...cooperatives, ...cooperatives_true],
            description: values?.description,
            token,
          };
          dispatchRedux.servicePatch(payload);
        } else {
          const payload = {
            cooperatives_ids: [...cooperatives, ...cooperatives_true],
            description: values?.description,
            token,
          };
          dispatchRedux.servicePost(payload);
        }
        return true;
      }}
    >
      {({ values, errors, setFieldValue, dirty }) => (
        <Box display="flex" flexDirection="column" gap={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="18px" fontWeight={700} color="secondary.main">
              {title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Switch
                checked={values?.active || !isId}
                onChange={(ev, checked) => {
                  setFieldValue('active', checked);
                }}
              />
              <Typography fontSize="14px" fontWeight={400} color="primary.main">
                {values?.active ? 'Ativo' : 'Inativo'}
              </Typography>
            </Box>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr"
            gap={3}
            alignItems="start"
          >
            <Form>
              <Box display="flex" flexDirection="column" gap={3}>
                <FormArrComponent
                  boxProps={{
                    gap: 4,
                  }}
                  fields={[
                    [
                      {
                        label: 'Nome do Perfil',
                        name: 'description',
                      },
                    ],
                    [
                      {
                        label: 'Filiais/Cooperativas',
                        name: 'description',
                        variant: 'label',
                      },
                    ],
                    ...cooperativesArray,
                  ]}
                />

                <Box alignSelf="end" display="flex" gap={4}>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    sx={{ color: 'white' }}
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || !dirty}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  );

  return (
    <AppLayout
      title={false}
      breadcrumb={['Configurações', isId ? title : 'Novo perfil']}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <BoxWhite loading={isLoading}>
          <Box display="flex" flexDirection="column" gap={3}>
            {up}
            {body}
          </Box>
        </BoxWhite>
      </Box>
    </AppLayout>
  );
};

export default ProfileCreateEditProfileScreen;
