import React, { useRef, useEffect, Fragment } from 'react';
import { Box, Typography } from '@mui/material';

import BoxGray from '@spot-spotview/components/Box/BoxGray.component';

const BoxReportComponent = ({ label, amount }) => (
  <BoxGray>
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle2" color="gray.300" fontWeight={500}>
        {label}
      </Typography>
      <Typography variant="subtitle1" color="gray.black" fontWeight={500}>
        {amount}
      </Typography>
    </Box>
  </BoxGray>
);

export default BoxReportComponent;
