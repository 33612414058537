import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

export default function DialogBaseComponent({
  visible,
  handleClose,
  children,
  actions,
  boxProps = {},
}: {
  actions?: any;
  [key: string]: any;
}) {
  return (
    <Dialog
      open={visible || false}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: 'background.default',
        },
      }}
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        gap={3}
        p={3}
        {...boxProps}
      >
        <Box alignSelf="end" mr={-1.5}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>{children}</Box>

        {actions && <Box>{actions}</Box>}
      </Box>
    </Dialog>
  );
}
