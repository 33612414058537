import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  trading_name: string;
  company_name: string;
  billing_day: string;
  address_zip_code: string;
  address_street: string;
  address_number: string;
  address_complement: string;
  address_neighborhood: string;
  address_city: string;
  address_state: string;
  address_receipt_path: string;
  phone_prefix: number;
  phone_number: number;
  api_key: string;
  type: string;
  token: string;
  sponsor: string;
  sponsor_email: string;
  sponsor_phone: string;
  sponsor_ramal: string;
};

export default async function patchApiCompaniesIdService(id, payload: Payload) {
  const { token, ...restPayload } = payload;

  const url = `companies/${id}`;

  return fetch(url, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    body: JSON.stringify(restPayload),
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
