const igbeService = async (ibge) => {
  const response = await window.fetch(
    `https://servicodados.ibge.gov.br/api/v3/malhas/municipios/${ibge}/metadados`
  );

  const result = await response.json();

  return [response.ok, result];
};

export default igbeService;
