import reducer from './polygons.reducer';
import * as constant from './polygons.constant';
import * as action from './polygons.action';
import * as selector from './polygons.selector';
import saga from './polygons.saga';
import initialState from './polygons.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
