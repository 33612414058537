import { flow } from 'lodash';

import { NAME } from './dialogDeleteProfile.constant';

import { selectState as selectStateDrawer } from '../dialogs.selector';

const selectStateKey = (state) => state[NAME];
const selectVisibleKey = (state) => state.visible;
const selectPayloadKey = (state) => state.payload;

export const selectState = flow(selectStateDrawer, selectStateKey);
export const selectVisible = flow(selectState, selectVisibleKey);
export const selectPayload = flow(selectState, selectPayloadKey);
