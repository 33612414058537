import React from 'react';

const LogoComponent = (props) => {
  const spotview = process.env.PUBLIC_URL + '/img/logo-spotview.png';
  const spoteye = process.env.PUBLIC_URL + '/img/logo-spoteye.svg';

  const variant = props?.variant === 'spoteye' ? spoteye : spotview;

  return <img src={variant} alt="logo" {...props} />;
};

export default LogoComponent;
