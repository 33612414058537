import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  description: string;
  cooperatives_ids: string[];
  active: any;
};

export default async function patchApiProfilesIdService(id, payload: Payload) {
  const url = ['profiles', `${id}`].join('/');

  console.log(payload);

  return fetch(url, {
    method: 'PATCH',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
