export const NAME = 'currentUser';

const CONTEXT = 'PROFILE::CURRENT_USER';

const SERVICE_POST_TOKEN_PARTNER = `${CONTEXT}::SERVICE::POST::TOKEN_PARTNER`;
const SERVICE_GET_USER_ID = `${CONTEXT}::SERVICE::GET::USER_ID`;
const SERVICE_PATCH = `${CONTEXT}::SERVICE::PATCH`;
const SERVICE_CHANGE_PROFILE = `${CONTEXT}::SERVICE::CHANGE_PROFILE`;

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;

const SERVICE = {
  POST_TOKEN_PARTNER: SERVICE_POST_TOKEN_PARTNER,
  GET_USER_ID: SERVICE_GET_USER_ID,
  PATCH: SERVICE_PATCH,
  CHANGE_PROFILE: SERVICE_CHANGE_PROFILE,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  UPDATE,
  SERVICE,
};
