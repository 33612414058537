import React from 'react';
import Plot from 'react-plotly.js';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import * as localeDictionary from 'plotly.js/lib/locales/pt-br.js';

import ndvi from '../store/ndvi';
import { CircularProgress } from '@mui/material';

const formatValue = (data) => JSON.parse(JSON.stringify(data));

const formatValues = (data) => ({
  line: formatValue(data.line),
  mode: formatValue(data.mode),
  name: formatValue(data.name),
  showlegend: formatValue(data.showlegend),
  x: formatValue(data.x),
  y: formatValue(data.y),
  type: formatValue(data.type),
});

const ChartNDVIComponent = ({
  glebaId,
  height = null,
  printMode = false,
}: any) => {
  const divRef: any = React.useRef(null);

  const loading = useSelector(ndvi.selector.selectLoading);

  const [key, setKey] = React.useState<any>(v4());
  const [data, setData] = React.useState<any>([]);
  const [width, setWith] = React.useState(0);

  const dataNdvi = useSelector(ndvi.selector.selectData);

  React.useEffect(() => {
    const currentGleba =
      Array.isArray(dataNdvi) && dataNdvi?.find((d) => d.glebe_id === glebaId);

    if (currentGleba) {
      setData(currentGleba?.data?.map(formatValues));
    }
  }, [dataNdvi]);

  React.useEffect(() => {
    if (loading === false) {
      setWith(divRef.current?.offsetWidth);

      setKey(v4());
    }
  }, [loading]);

  const hasData = Array.from(data).length > 0;

  return (
    <>
      <Box
        ref={divRef}
        sx={{
          '.main-svg': {
            width: '100% !important',
          },
        }}
      >
        {loading === true && (
          <Box
            height={height || 200}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}

        {loading === false && (
          <>
            {!hasData && (
              <Box
                height={height || 200}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <Typography>Dados de NDVI não estão disponíveis</Typography>
              </Box>
            )}

            {hasData && (
              <Plot
                key={key}
                data={data}
                layout={{
                  height: height || 200,
                  width: width || 1200,
                  yaxis: !printMode
                    ? {
                        autorange: false,
                        range: [0, 1],
                      }
                    : {
                        autorange: true,
                      },
                  legend: {
                    orientation: 'h',
                    xanchor: 'center',
                    x: '0.5',
                  },
                  plot_bgcolor: '#ECF0F3',
                  margin: {
                    l: 30,
                    r: 10,
                    t: 25,
                  },
                  font: {
                    family: '"Raleway",sans-serife',
                    size: 14,
                    color: '#053A65',
                  },
                }}
                config={{
                  locales: { 'pt-BR': localeDictionary },
                  locale: 'pt-BR',
                  responsive: true,
                  staticPlot: printMode,
                }}
                revision={0}
                graphDiv="graph"
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ChartNDVIComponent;
