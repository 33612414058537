import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Filters = {
  offset: string;
  limit: string;
  code: string;
  bacen_code: string;
  document_number: string;
  hash_code: string;
  start_created_at: string;
  end_created_at: string;
};

const today = new Date();

export default async function getApiReportsRegisterblockchainService(
  filters: Filters
) {
  const newFilters = {
    ...filters,
    start_created_at:
      filters?.start_created_at ||
      new Date(today.setMonth(today.getMonth() - 13)),
    end_created_at: filters?.end_created_at || new Date(),
  };

  const filtersStringfy = qs.stringify(newFilters);

  const url = ['reports/register-blockchain', filtersStringfy].join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'REPORT',
    auth: true,
  });
}
