const ROUTES = {
  ROOT: '/reports',
};

const REPORTS_ROUTES = {
  ROOT: ROUTES.ROOT,
};

export const NAMESPACE = ROUTES.ROOT;

export default REPORTS_ROUTES;
