import { combineReducers } from 'redux';

import filters from './filters';
import operations from './reports';

const reducer = combineReducers({
  [operations.name]: operations.reducer,
  [filters.name]: filters.reducer,
});

export default reducer;
