import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  description: string;
  cooperatives_id: string[];
  token: string;
};

export default async function postApiProfilesService(payload: Payload) {
  const url = ['profiles'].join('/');

  const { token, ...restPayload } = payload;

  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(restPayload),
    auth: !token,
    ...Object.assign(
      {},
      token && {
        headers: {
          Authorization: token,
        },
      }
    ),
  });
}
