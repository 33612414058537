import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  name: string;
  document_number: string;
  lat: string;
  lon: string;
  zip_code: string;
  city: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  sponsor: string;
  sponsor_email: string;
  sponsor_phone: string;
  sponsor_ramal: string;
  datasource_id: string;
  cli_plan_id: string;
  api_key: string;
};

export default async function patchApiClientsIdService(
  id: string,
  payload: Payload
) {
  const url = ['clients', `${id}`].join('/');

  return fetch(url, {
    method: 'PATCH',
    ms: 'USER',
    body: JSON.stringify(payload),
    auth: true,
  });
}
