import flow from 'lodash/flow';
import { takeLatest, select, call, put, take } from 'redux-saga/effects';

import auth from '@spot/shared-store/auth';
import toaster from '@spot/shared-store/toaster';

import currentState from '.';

import postApiAuthChangeProfile from '@spot/shared-services/account/postApiAuthChangeProfile.service';
import accounts from '../accounts';

const handleServiceGetUserId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    serviceGetAccountId: flow(accounts.action.serviceGetId, put),
    resetState: flow(accounts.action.resetState, put),
  };

  const user = yield select(auth.selector.selectUser);

  try {
    yield handlers.fetchStart();

    yield handlers.serviceGetAccountId({
      account_id: user?.user?.id,
    });

    while (true) {
      const success = accounts.constant.ACTION_TYPES.FETCH.END;
      const error = accounts.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        yield handlers.resetState();

        throw payload;
      }

      if (type === success) {
        yield handlers.resetState();

        return yield handlers.fetchEnd(payload);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    servicePatch: flow(accounts.action.servicePatch, put),
    resetState: flow(accounts.action.resetState, put),
  };

  const user = yield select(auth.selector.selectUser);

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    yield handlers.servicePatch({
      account_id: user?.user?.id,
      ...payload,
    });

    while (true) {
      const success = accounts.constant.ACTION_TYPES.FETCH.END;
      const error = accounts.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        yield handlers.resetState();

        throw payload;
      }

      if (type === success) {
        yield handlers.resetState();

        return yield handlers.fetchEnd(payload);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleUpdateProfile = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    servicePatch: flow(accounts.action.servicePatch, put),
    resetState: flow(accounts.action.resetState, put),
  };

  const user = yield select(auth.selector.selectUser);

  const { payload } = action;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(postApiAuthChangeProfile, {
      ...payload,
    });

    const { token } = result;
    yield put(
      auth.action.setToken(
        token,
        process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTEYE,
        process.env.REACT_APP_COOKIE_DOMAIN_SPOTPROEYE
      )
    );
    window.location.reload();

    while (true) {
      const success = accounts.constant.ACTION_TYPES.FETCH.END;
      const error = accounts.constant.ACTION_TYPES.FETCH.ERROR;

      const action = yield take([error, success]);

      const { type, payload } = action;

      if (type === error) {
        yield handlers.resetState();

        throw payload;
      }

      if (type === success) {
        yield handlers.resetState();

        return yield handlers.fetchEnd(payload);
      }
    }
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_USER_ID,
    handleServiceGetUserId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.CHANGE_PROFILE,
    handleUpdateProfile
  );
}

export default {
  watch,
};
