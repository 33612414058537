import React from 'react';
import flow from 'lodash/flow';
import { Box, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { useTheme } from '@mui/styles';
import misc from '@spot-spotview/store/configs/misc';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import AppLayout from '@spot-spotview/layouts/App.layout';

import ROUTES from '@spot-spotview/constants/routes.constant';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import BoxSummary from '@spot-spotview/components/Box/BoxSummary.component';
import TableComponent from '@spot-spotview/components/Table.component';
import ChartPieComponent from '@spot-spotview/components/ChartPie.component';
import ChartPieReferenceComponent from '@spot-spotview/components/ChartPieReference.component';
import ChartBarComponent from '@spot-spotview/components/ChartBar.component';
import BoxFilter from '@spot-spotview/components/Box/BoxFilter.component';
import Badge from '@mui/material/Badge';

import toNumber from '@spot-spotview/utils/toNumber.util';
import toCurrency from '@spot-spotview/utils/toCurrency.util';
import toDecimal from '@spot-spotview/utils/toDecimal.util';

import summary from '../store/summary';

import totals from '../store/totals';
import details from '../store/details';
import alertsState from '../store/alerts';
import domain from '../store/domain';
import filtersState from '../store/filters';
import dashboard from '../store';

const DashboardScreen = () => {
  const [key, setKey] = React.useState(v4());

  const history = useHistory();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateFilters: flow(filtersState.action.updateFilters, dispatch),
    resetState: flow(filtersState.action.resetState, dispatch),
    serviceGetAll: flow(dashboard.action.serviceGetAll, dispatch),
  };

  const selectorRedux = {
    dataSummary: useSelector(summary.selector.selectData),
    dataTotals: useSelector(totals.selector.selectData),
    dataDetails: useSelector(details.selector.selectDataResults),
    dataDetailsMetadata: useSelector(details.selector.selectDataMetadata),
    dataAlerts: useSelector(alertsState.selector.selectData),
    dataDomain: useSelector(domain.selector.selectData),
    filters: useSelector(filtersState.selector.selectState),
    loadingDetails: useSelector(details.selector.selectLoading),
    loadingTotals: useSelector(totals.selector.selectLoading),
    loadingSummary: useSelector(summary.selector.selectLoading),
    loadingAlerts: useSelector(alertsState.selector.selectLoading),
    mode: useSelector(misc.selector.selectMode),
  };

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetState();
    };
  }, []);

  const { dataDomain, filters: filtersSt } = selectorRedux || {};

  const columns = [
    {
      label: 'Nº',
      name: 'code',
    },
    {
      label: 'Alertas',
      name: 'count_alerts',
      render: ({ count_alerts }) => (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          sx={{
            '.MuiBadge-badge': {
              align: 'center',
              color: 'gray',
              fontSize: '14px',
              fontWeight: 700,
              bgcolor:
                count_alerts > 1
                  ? '#F1A57D'
                  : count_alerts == 1
                  ? '#FFE7B8'
                  : '#D6D8DC',
            },
          }}
        >
          <Badge badgeContent={count_alerts}></Badge>
        </Box>
      ),
    },
    {
      label: 'Produtor',
      name: 'owner',
    },
    {
      label: 'Cultura',
      name: 'verified_culture_name',
    },
    {
      label: 'Solo',
      name: 'soil',
    },
    {
      label: 'Localização',
      name: 'localizacao',
      render: ({ city, uf }) => (
        <Typography color="gray.400" variant="subtitle2">
          {city}, {uf}
        </Typography>
      ),
    },
    {
      label: 'Recebíveis',
      name: 'recebiveis',
      render: ({ financed }) => (
        <Typography color="gray.400" variant="subtitle2">
          {toCurrency(financed)}
        </Typography>
      ),
    },
    {
      name: 'action',
      render: ({ id }) => (
        <Button
          onClick={() => history.push(ROUTES.OPERATIONS.ID(id))}
          sx={{ color: 'info.main' }}
        >
          Ver mais
        </Button>
      ),
    },
  ];

  const {
    cities = [],
    cultures = [],
    cycles = [],
    ufs = [],
    alert_types = [],
  } = dataDomain || {};

  const filtersArr = (values) => {
    return [
      [
        {
          label: 'Cultura',
          name: 'culture_name',
          options: [{}, ...orderBy(cultures, 'culture_name')]
            .filter((c) => c.culture_name !== '')
            .map((c) => ({
              label: c.culture_name,
              value: c.culture_name,
            })),
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
        {
          label: 'Ciclo',
          name: 'cycle',
          options: [{}, ...orderBy(cycles, 'cycle')]
            .filter((c) => c.cycle !== '')
            .map((c) => ({
              label: c.cycle,
              value: c.cycle,
            })),
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
        {
          label: 'Alerta',
          name: 'alert_type',
          options: [{}, ...orderBy(alert_types, 'alert_type')].map((c) => ({
            label: c?.description,
            value: c?.type,
          })),
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
        {
          label: 'UF',
          name: 'uf',
          options: [{}, ...orderBy(ufs, 'uf')].map((c) => ({
            label: c?.name,
            value: c?.uf,
          })),
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
        {
          label: 'Cidade',
          name: 'city',
          options: [
            {},
            ...orderBy(cities, 'city')
              .filter((c) => c.uf === values.uf)
              .map((c) => ({
                label: c.city,
                value: c.city,
              })),
          ],
          textFieldProps: {
            select: true,
            SelectProps: {
              native: true,
            },
          },
        },
      ],
    ];
  };

  const filters = (
    <Formik
      key={key}
      enableReinitialize
      initialValues={{
        limit: selectorRedux.filters.limit,
        offset: selectorRedux.filters.offset,
        city: selectorRedux.filters.city,
        uf: selectorRedux.filters.uf,
        culture_name: selectorRedux.filters.culture_name,
        cycle: selectorRedux.filters.cycle,
        alert_type: selectorRedux.filters.alert_type,
      }}
      onSubmit={async (values) => {
        dispatchRedux.updateFilters(values);

        return true;
      }}
    >
      {({ values }) => (
        <Form>
          <BoxFilter
            formik
            fields={filtersArr(values)}
            key={`filters-${cycles.length}`}
            onEmpty={() => {
              dispatchRedux.resetState();

              setKey(v4());

              dispatchRedux.serviceGetAll();
            }}
          />
        </Form>
      )}
    </Formik>
  );

  const summaryElm = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
      <BoxSummary
        label="Total de Operações"
        amount={toNumber(selectorRedux?.dataTotals?.total_operation)}
        img="/img/operation.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Total de Hectares"
        amount={toDecimal(selectorRedux?.dataTotals?.total_area)}
        img="/img/hectare.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Total de Recebíveis"
        amount={toCurrency(selectorRedux?.dataTotals?.total_receivable)}
        img="/img/receives.png"
        loading={selectorRedux.loadingTotals}
      />
    </Box>
  );
  const alerts = (
    <Box display="grid" gridTemplateColumns="60% 1fr" gap={3}>
      <BoxWhite loading={selectorRedux.loadingAlerts}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography color="secondary.main" fontWeight={600}>
              Alertas
            </Typography>
            <Typography color="gray.400" variant="subtitle2">
              Acompanhe todos os alertas relacionados ao plantio e compliance
              das terras
            </Typography>
          </Box>
          <Box display="flex" gap={4} alignItems="center">
            <Box width={200} height={160}>
              <ChartPieComponent data={selectorRedux.dataAlerts} />
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <ChartPieReferenceComponent data={selectorRedux.dataAlerts} />
            </Box>
          </Box>
        </Box>
      </BoxWhite>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="space-between"
      >
        <BoxWhite loading={selectorRedux.loadingTotals}>
          <Box display="flex" flexDirection="column">
            <Typography color="gray.400" variant="subtitle2" align="center">
              Hectares / Operações
            </Typography>
            <Typography color="secondary.main" fontWeight={800} align="center">
              {selectorRedux?.dataTotals?.total_operation_area}
            </Typography>
          </Box>
        </BoxWhite>
        <BoxWhite loading={selectorRedux.loadingTotals}>
          <Box display="flex" flexDirection="column">
            <Typography color="gray.400" variant="subtitle2" align="center">
              Recebíveis / hectares
            </Typography>
            <Typography color="secondary.main" fontWeight={800} align="center">
              {toCurrency(selectorRedux?.dataTotals?.total_receivable_area)}
            </Typography>
          </Box>
        </BoxWhite>
        <BoxWhite loading={selectorRedux.loadingTotals}>
          <Box display="flex" flexDirection="column">
            <Typography color="gray.400" variant="subtitle2" align="center">
              Recebíveis / operações
            </Typography>
            <Typography color="secondary.main" fontWeight={800} align="center">
              {toCurrency(
                selectorRedux?.dataTotals?.total_receivable_operation
              )}
            </Typography>
          </Box>
        </BoxWhite>
      </Box>
    </Box>
  );

  const total = (
    <BoxWhite loading={selectorRedux.loadingSummary}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography color="secondary.main" fontWeight={600}>
          Total de hectares monitorados por cultura (ha)
        </Typography>

        <Box height="300px">
          <ChartBarComponent data={selectorRedux.dataSummary || []} />
        </Box>
      </Box>
    </BoxWhite>
  );

  const table = (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography color="secondary.main" fontWeight={500} variant="subtitle1">
          20 operações com mais alertas
        </Typography>

        <TableComponent
          columns={columns}
          name="details"
          loading={selectorRedux.loadingDetails}
          data={selectorRedux.dataDetails}
          handleUpdateFilters={dispatchRedux.updateFilters}
          metadata={selectorRedux.dataDetailsMetadata?.resultset}
          pagination={false}
        />
      </Box>
    </BoxWhite>
  );

  return (
    <AppLayout title="Dashboard">
      <Box display="flex" flexDirection="column" gap={3}>
        {filters}
        {summaryElm}
        {alerts}
        {total}
        {table}
      </Box>
    </AppLayout>
  );
};

export default React.memo(DashboardScreen);
