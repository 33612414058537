import { takeLatest, call, put, delay, select } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';
import auth from '@spot/shared-store/auth';
import flow from 'lodash/flow';

import postApiAuthChangeCompanyService from '@spot/shared-services/account/postApiAuthChangeCompany.service';

import decode from 'jwt-decode';
import currentState from '.';

const keyCookie = process.env.REACT_APP_COOKIE_KEY_TOKEN_SPOTEYE;

const domainCookie = process.env.REACT_APP_COOKIE_DOMAIN_SPOTEYE;

function* handleServicePost({ payload }: any) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const user = yield select(auth.selector.selectUser);

    const params = new URLSearchParams(window.location.search);

    const [success, result] = yield call(postApiAuthChangeCompanyService, {
      company_id: user.user.active.company_id,
      token: payload.token,
    });

    if (!success) {
      throw result;
    }

    const { token } = result;

    yield put(auth.action.setToken(token, keyCookie, domainCookie));

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield put(
      toaster.action.show({ message: result?.message, variant: 'error' })
    );

    return yield handlers.fetchError(result);
  }
}

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
}

export default {
  watch,
};
