import reducer from './operations.reducer';
import * as constant from './operations.constant';
import * as action from './operations.action';
import * as selector from './operations.selector';
import saga from './operations.saga';
import initialState from './operations.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
