import reducer from './alerts.reducer';

// import { handleGet } from 'louhelpers';

import * as constant from './alerts.constant';
import * as action from './alerts.action';
import * as selector from './alerts.selector';
import saga from './alerts.saga';
import initialState from './alerts.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};

// configs = {

// }

// reducer = {

// }

// saga = {

//   ...sagaDefaultCrud(configs),
//   DELETE: () {

//   }
// }
