import { all } from 'redux-saga/effects';

import dialogDeleteAccount from './dialogDeleteAccount';
import dialogDeleteProfile from './dialogDeleteProfile';
import dialogDeleteRelation from './dialogDeleteRelation';
import dialogInviteAccount from './dialogInviteAccount';

function* watch() {
  yield all([
    dialogDeleteAccount.saga.watch(),
    dialogDeleteProfile.saga.watch(),
    dialogDeleteRelation.saga.watch(),
    dialogInviteAccount.saga.watch(),
  ]);
}

export default {
  watch,
};
