import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiPolygonsByIdService(id: string) {
  const url = `polygons/${id}`;

  return fetch(url, {
    method: 'GET',
    ms: 'POLYGONS',
    auth: true,
  });
}
