import React from 'react';
import { Box, Typography, Button } from '@mui/material';

import BoxWhite from '@spot-spotview/components/Box/BoxWhite.component';
import FormArr from '@spot-spotview/components/FormArr.component';
import FormArrFormik from '@spot-spotview/components/FormArrFormik.component';

const BoxFilterComponent = ({
  fields,
  title = '',
  formik = false,
  onEmpty = () => {},
}) => {
  const FormComponent = formik ? FormArrFormik : FormArr;

  return (
    <BoxWhite>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" flexDirection="column" gap={2}>
          {title && (
            <Typography variant="subtitle1" color="secondary.main">
              {title}
            </Typography>
          )}
          <FormComponent boxProps={{ gap: 3 }} fields={fields} />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={3}
        >
          <Button variant="outlined" size="large" onClick={onEmpty}>
            Limpar campos
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ color: 'white' }}
          >
            Pesquisar
          </Button>
        </Box>
      </Box>
    </BoxWhite>
  );
};

export default BoxFilterComponent;
