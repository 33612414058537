import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

type Payload = {
  name: string;
  document_number: string;
  agency: string;
};

export default async function postApiCooperativesService(payload: Payload) {
  const url = ['cooperatives'].join('/');

  return fetch(url, {
    method: 'POST',
    ms: 'ACCOUNT',
    body: JSON.stringify(payload),
    auth: true,
  });
}
