import { combineReducers } from 'redux';

import auth from '@spot/shared-store/auth';
import router from '@spot/shared-store/router';
import toaster from '@spot/shared-store/toaster';

import login from '@spot-spotview/modules/login/store';

import configs from './configs';

import profile from '@spot-spotview/modules/profile/store';
import dashboard from '@spot-spotview/modules/dashboard/store';
import operations from '@spot-spotview/modules/operations/store';
import polygons from '@spot-spotview/modules/polygons/store';
import reports from '@spot-spotview/modules/reports/store';
import blockchain from '@spot-spotview/modules/blockchain/store';

const appReducer = combineReducers({
  [auth.name]: auth.reducer,
  [router.name]: router.reducer,
  [toaster.name]: toaster.reducer,
  [login.name]: login.reducer,
  [configs.name]: configs.reducer,
  [dashboard.name]: dashboard.reducer,
  [profile.name]: profile.reducer,
  [operations.name]: operations.reducer,
  [blockchain.name]: blockchain.reducer,
  [reports.name]: reports.reducer,
  [polygons.name]: polygons.reducer,
});

const rootReducer = (state, action) => {
  if (auth.constant.ACTION_TYPES.TOKEN.UNSET === action.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
