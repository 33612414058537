import qs from 'qs';

import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiOperationsCultureService() {
  const url = ['operations', 'culture'].join('/');

  return fetch(url, {
    method: 'GET',
    ms: 'OPERATION',
    auth: true,
  });
}
