import reducer from './dialogInviteAccount.reducer';
import * as action from './dialogInviteAccount.action';
import * as selector from './dialogInviteAccount.selector';
import * as constant from './dialogInviteAccount.constant';
import initialState from './dialogInviteAccount.initialState';
import subscriber from './dialogInviteAccount.subscriber';
import saga from './dialogInviteAccount.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
