import React from 'react';
import flow from 'lodash/flow';
import { Box, Typography, Button, Dialog } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useHistory } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { v4 } from 'uuid';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import ROUTES from '@spot-spotview/constants/routes.constant';

import AppLayout from '@spot-spotview/layouts/App.layout';

import BoxSummary from '@spot-spotview/components/Box/BoxSummary.component';
import BoxFilter from '@spot-spotview/components/Box/BoxFilter.component';

import Badge from '@mui/material/Badge';

import TableComponent from '@spot-spotview/components/Table.component';

import toNumber from '@spot-spotview/utils/toNumber.util';
import toCurrency from '@spot-spotview/utils/toCurrency.util';
import toDecimal from '@spot-spotview/utils/toDecimal.util';

import filtersState from '../store/filters';
import operations from '../store/operations';
import operationsModule from '../store';
import totals from '../store/totals';
import domain from '../store/domain';
import domainDashboard from '@spot-spotview/modules/dashboard/store/domain';

const OperationsScreen = () => {
  const [favs, setFavs] = React.useState<Array<any>>([]);
  const [showDate, setShowDate] = React.useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const selectorRedux = {
    filters: useSelector(filtersState.selector.selectState),
    dataTotals: useSelector(totals.selector.selectData),
    dataOperations: useSelector(operations.selector.selectDataResults),
    dataOperationsMetadata: useSelector(operations.selector.selectDataMetadata),
    loadingOperations: useSelector(operations.selector.selectLoading),
    loadingTotals: useSelector(totals.selector.selectLoading),
    dataDomain: useSelector(domain.selector.selectData),
    dataDomainDashboard: useSelector(domainDashboard.selector.selectData),
  };

  const dispatchRedux = {
    updateFilters: flow(filtersState.action.updateFilters, dispatch),
    resetState: flow(filtersState.action.resetState, dispatch),
    serviceGetAll: flow(operationsModule.action.serviceGetAll, dispatch),
    servicePatchBookmark: flow(
      operations.action.servicePatchBookmark,
      dispatch
    ),
  };

  React.useEffect(() => {
    const favs = selectorRedux.dataOperations?.rows?.filter(
      (o) => o.bookmark?.flagged
    );

    if (favs) {
      setFavs(favs.map((f) => f.id));
    }
  }, [selectorRedux.dataOperations]);

  const fields = [
    [
      {
        label: 'Início',
        name: 'start_created_at',
        variant: 'date',
        textFieldProps: {
          // onClick: () => setShowDate(true),
          InputProps: {
            endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
            readOnly: true,
          },
        },
      },
      {
        label: 'Fim',
        name: 'end_created_at',
        variant: 'date',
        textFieldProps: {
          // onClick: () => setShowDate(true),
          InputProps: {
            endAdornment: <CalendarMonthIcon sx={{ color: 'gray.300' }} />,
            readOnly: true,
          },
        },
      },
      {
        label: 'Cultura',
        name: 'culture_id',
        options: [
          {},
          ...Object.assign(
            [],
            selectorRedux.dataDomain?.filter((c) => c.name !== '')
          ),
        ].map((c) => ({
          label: c.name,
          value: c.id,
        })),
        textFieldProps: {
          select: true,
          SelectProps: {
            native: true,
          },
        },
      },
      {
        label: 'Alerta',
        name: 'alert_type',
        options: [
          {},
          ...Object.assign([], selectorRedux.dataDomainDashboard?.alert_types),
        ].map((c) => ({
          label: c.description,
          value: c.type,
        })),
        textFieldProps: {
          select: true,
          SelectProps: {
            native: true,
          },
        },
      },
    ],
    [
      {
        label: 'CPF / CNPJ / Código Bacen ou da Operação',
        name: 'document_number',
      },
      {
        label: 'Produtor',
        name: 'productor',
      },
      {
        label: 'Mostrar somente favoritos',
        name: 'only_bookmark',
        variant: 'checkbox',
      },
    ],
  ];

  const filter = (
    <Formik
      enableReinitialize
      initialValues={selectorRedux.filters.currentFilter}
      onSubmit={async (values) => {
        dispatchRedux.updateFilters(values);

        return true;
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <BoxFilter
            formik
            fields={fields}
            title="Data de criação"
            onEmpty={() => {
              dispatchRedux.resetState();
              dispatchRedux.serviceGetAll();
            }}
          />

          <Dialog open={showDate} onClose={() => setShowDate(false)}>
            <DateRange
              weekdayDisplayFormat="EEEEEE"
              monthDisplayFormat="MMMM yyyy"
              locale={ptBR}
              showDateDisplay={false}
              months={2}
              direction="horizontal"
              ranges={[
                {
                  startDate: values.start_created_at || new Date(),
                  endDate: values.end_created_at || new Date(),
                  key: 'selection',
                },
              ]}
              onChange={({ selection }) => {
                setFieldValue('start_created_at', selection.startDate);
                setFieldValue('end_created_at', selection.endDate);
              }}
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );

  const columns = [
    {
      name: 'fav',
      render: ({ id }) => {
        const hasFav = favs.includes(id);

        return (
          <Box
            component={StarIcon}
            color={hasFav ? 'warning.main' : 'gray.200'}
            cursor="pointer"
            onClick={() => {
              dispatchRedux.servicePatchBookmark(id);

              if (hasFav) {
                const newFav = [...favs].filter((f) => f !== id);

                return setFavs(newFav);
              }

              return setFavs([...favs, id]);
            }}
          />
        );
      },
    },
    {
      label: 'Nº',
      name: 'code',
    },
    {
      label: 'Alertas',
      name: 'count_alerts',
      render: ({ count_alerts }) => (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          sx={{
            '.MuiBadge-badge': {
              align: 'center',
              color: 'gray',
              fontSize: '14px',
              fontWeight: 700,
              bgcolor:
                count_alerts > 1
                  ? '#F1A57D'
                  : count_alerts == 1
                  ? '#FFE7B8'
                  : '#D6D8DC',
            },
          }}
        >
          <Badge badgeContent={count_alerts}></Badge>
        </Box>
      ),
    },
    {
      label: 'Produtor',
      name: 'owner',
    },
    {
      label: 'Documento',
      name: 'owner_document',
    },
    {
      label: 'Cultura',
      name: 'culture_name',
    },
    {
      label: 'Vencimento da CPR',
      name: 'due',
      render: ({ due }) => (
        <Typography color="gray.400" variant="subtitle2">
          {format(new Date(due), 'dd/MM/yyy')}
        </Typography>
      ),
    },
    {
      label: 'Recebíveis',
      name: 'financed',
      render: ({ financed }) => (
        <Typography color="gray.400" variant="subtitle2">
          {toCurrency(financed)}
        </Typography>
      ),
    },
    {
      label: 'Área',
      name: 'area_ha',
    },
    {
      name: 'action',
      render: ({ id }) => (
        <Button
          onClick={() => history.push(ROUTES.OPERATIONS.ID(id))}
          sx={{
            color: 'info.main',
          }}
        >
          Ver mais
        </Button>
      ),
    },
  ];

  const table = (
    <TableComponent
      columns={columns}
      name="reports"
      loading={selectorRedux.loadingOperations}
      data={selectorRedux.dataOperations?.rows}
      metadata={selectorRedux.dataOperationsMetadata?.resultset}
      handleUpdateFilters={dispatchRedux.updateFilters}
    />
  );

  const summary = (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={4}>
      <BoxSummary
        label="Total de Operações"
        amount={toNumber(selectorRedux.dataTotals?.total_operation)}
        img="/img/operation.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Total de Alertas"
        img="/img/alert.png"
        amount={toNumber(selectorRedux.dataTotals?.total_alerts)}
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Total de Hectares"
        amount={selectorRedux?.dataTotals?.total_area}
        img="/img/hectare.png"
        loading={selectorRedux.loadingTotals}
      />
      <BoxSummary
        label="Total de Recebíveis"
        img="/img/receives.png"
        amount={toCurrency(selectorRedux.dataTotals?.total_receivables)}
        loading={selectorRedux.loadingTotals}
      />
    </Box>
  );

  return (
    <AppLayout title="Operações">
      <Box display="flex" flexDirection="column" gap={5}>
        {filter}

        <Box display="flex" flexDirection="column" gap={3}>
          {summary}
          {table}
        </Box>
      </Box>
    </AppLayout>
  );
};

export default OperationsScreen;
