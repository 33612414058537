const ROUTES = {
  ROOT: '/login',
  AUTH: '/login/auth',
  NEW_PASSWORD: '/login/new-password',
};

const LOGIN_ROUTES = {
  ROOT: ROUTES.ROOT,
  NEW_PASSWORD: ROUTES.NEW_PASSWORD,
  AUTH: ROUTES.AUTH,
};

export const NAMESPACE = ROUTES.ROOT;

export default LOGIN_ROUTES;
