import { all } from 'redux-saga/effects';

import domain from './domain';
import totals from './totals';
import operations from './operations';
import compliance from './compliance';
import operation from './operation';
import weather from './weather';
import ndvi from './ndvi';

function* watch() {
  yield all([
    ndvi.saga.watch(),
    weather.saga.watch(),
    operation.saga.watch(),
    compliance.saga.watch(),
    totals.saga.watch(),
    operations.saga.watch(),
    domain.saga.watch(),
  ]);
}

export default {
  watch,
};
