import { flow } from 'lodash';
import { NAME } from './totals.constant';

import { selectState as selectStatePolygons } from '../polygons.selector';

const selectStateKey = (state) => state[NAME];
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectDataKey = (state) => state.data;
const selectDataResultsKey = (state: any) => state?.results;
const selectDataMetadataKey = (state: any) => state?.metadata;
const selectFiltersKey = (state) => state.filters;

export const selectState = flow(selectStatePolygons, selectStateKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectData = flow(selectState, selectDataKey);
export const selectDataResults = flow(
  selectState,
  selectDataKey,
  selectDataResultsKey
);
export const selectDataMetadata = flow(
  selectState,
  selectDataKey,
  selectDataMetadataKey
);
export const selectFilters = flow(selectState, selectFiltersKey);
