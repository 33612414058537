import flow from 'lodash/flow';
import pickBy from 'lodash/pickBy';
import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import toaster from '@spot/shared-store/toaster';

import getApiAccountsService from '@spot/shared-services/account/getApiAccounts.service';
import getApiAccountsIdService from '@spot/shared-services/account/getApiAccountsId.service';
import patchApiAccountsIdService from '@spot/shared-services/account/patchApiAccountsId.service';
import deleteApiAccountsIdService from '@spot/shared-services/account/deleteApiAccountsId.service';
import deleteApiAccountsIdProfileService from '@spot/shared-services/account/deleteApiAccountsIdProfile.service';

import getApiAccountsid from '@spot/shared-services/account/getApiAccountsId.service';

import currentState from '.';

const handleServiceGet = function* (action) {
  const seletors = {
    filters: yield select(currentState.selector.selectFilters),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(() =>
      getApiAccountsService(seletors.filters)
    );

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetId = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;
  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(getApiAccountsid, payload?.account_id);

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServicePatch = function* (action) {
  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload } = action;

  try {
    yield handlers.fetchStart();
    const [success, result] = yield call(
      patchApiAccountsIdService as any,
      payload?.account_id,
      pickBy({
        name: payload?.nome,
        email: payload?.email,
        password: payload?.senha,
        token: payload?.token,
        empty_photo: payload?.empty_photo,
        photo: payload?.photo?.file,
      })
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário atualizado com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceDelete = function* (action) {
  const selectors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload = {} } = action || {};

  const { account_id } = payload;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      deleteApiAccountsIdService,
      account_id
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Usuário removido com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceDeleteRelation = function* (action) {
  const selectors = {
    data: yield select(currentState.selector.selectData),
  };

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  const { payload = {} } = action || {};

  const { account_id, profile_id } = payload;

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      deleteApiAccountsIdProfileService,
      account_id,
      profile_id
    );

    if (!success) {
      throw result;
    }

    yield handlers.show({
      message: 'Relação de perfil removida com sucesso',
      variant: 'success',
    });

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleServiceGet
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_ID,
    handleServiceGetId
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.DELETE,
    handleServiceDelete
  );

  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.DELETE_RELATION,
    handleServiceDeleteRelation
  );
}

export default {
  watch,
};
