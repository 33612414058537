import reducer from './datasource.reducer';
import * as constant from './datasource.constant';
import * as action from './datasource.action';
import * as selector from './datasource.selector';
import saga from './datasource.saga';
import initialState from './datasource.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
