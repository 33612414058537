import { ACTION_TYPES } from './dialogInviteAccount.constant';

export const servicePost = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST,
  payload,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchEnd = (payload) => ({
  type: ACTION_TYPES.FETCH.END,
  payload,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const open = (payload) => ({
  type: ACTION_TYPES.SET.OPEN,
  payload,
});

export const close = () => ({
  type: ACTION_TYPES.SET.CLOSE,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
