import React, { PureComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import { Typography, Box, TextField } from '@mui/material';
import format from 'date-fns/format';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { uniqBy, filter } from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import normalizeWeatherConstant from '../constants/normalizeWeather.constant';

import toDateUtil from '@spot-spotview/utils/toDate.util';

import BoxWhiteComponent from '@spot-spotview/components/Box/BoxWhite.component';

import weather from '../store/weather';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const payloadObj = payload?.[0];

    return (
      <BoxWhiteComponent>
        <Box display="flex" flexDirection="column">
          <Typography color="gray.black" variant="subtitle1" fontWeight={500}>
            Data: <strong>{toDateUtil(payloadObj?.payload?.date)}</strong>
          </Typography>
          <Typography variant="subtitle1" fontWeight={500}>
            Tempo:{' '}
            <strong>
              {normalizeWeatherConstant[payloadObj?.payload?.Tempo]}
            </strong>
          </Typography>
          <Typography color="blue" variant="subtitle1" fontWeight={500}>
            Precipitação (mm):{' '}
            <strong>{payloadObj?.payload?.Precipitacao}</strong>
          </Typography>
          <Typography color="red" variant="subtitle1" fontWeight={500}>
            Temperatura (°C):{' '}
            <strong>{payloadObj?.payload?.Temperatura}</strong>
          </Typography>
        </Box>
      </BoxWhiteComponent>
    );
  }

  return null;
};

export default function ChartWeatherComponent(props) {
  const [filterBy, setFilterBy] = React.useState('');

  const { printMode = false } = props;

  const selectorRedux = {
    data: useSelector(weather.selector.selectData),
  };

  const { data: dataState } = selectorRedux;

  const dataFormatteted = dataState?.daily?.time?.map((date, index) => ({
    name: date,
    date: date,
    dateLabel: format(new Date(date), 'dd MMM yyyy', {
      locale: ptBR,
    }),
    Precipitacao: dataState?.daily?.precipitation_sum?.[index],
    Temperatura: dataState?.daily?.temperature_2m_mean?.[index],
    Tempo: dataState?.daily?.weathercode?.[index],
  }));

  const uniqWeather = uniqBy(dataFormatteted, 'Tempo')?.map(
    (dft: any) => dft.Tempo
  );

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {!printMode && (
        <Box display="flex" flexDirection="column" mt={2}>
          <Typography variant="caption">Filtrar por:</Typography>
          <TextField
            select
            SelectProps={{ native: true }}
            size="small"
            sx={{ maxWidth: 250 }}
            value={filterBy}
            onChange={({ target: { value } }) => setFilterBy(value)}
          >
            <option value="">Todos</option>
            {uniqWeather?.map((uw) => (
              <option value={uw} key={uw}>
                {normalizeWeatherConstant[uw]}
              </option>
            ))}
          </TextField>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        height={props?.height || '100%'}
        position="relative"
      >
        <Box pt="calc(16px + 32px)" position="absolute" left={'-32px'} top={0}>
          <Typography
            variant="subtitle2"
            color="red"
            sx={{ transform: 'rotate(270deg)' }}
          >
            Temperatura (°C)
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={(dataFormatteted || []).filter((df) =>
              !filterBy ? true : df.Tempo == filterBy
            )}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateLabel"
              // interval={30}
              style={{
                fontSize: '16px',
              }}
            />
            <YAxis
              yAxisId="left"
              style={{
                fontSize: '16px',
              }}
            />
            <Legend />
            <YAxis
              yAxisId="right"
              orientation="right"
              style={{
                fontSize: '16px',
              }}
            />
            {!printMode && (
              <Tooltip
                content={(props) => <CustomTooltip {...(props as any)} />}
              />
            )}
            <Line
              dot={false}
              yAxisId="left"
              type="monotone"
              dataKey="Temperatura"
              name="Temperatura"
              stroke="red"
              activeDot={{ r: 8 }}
            />
            <Line
              dot={false}
              yAxisId="right"
              type="monotone"
              dataKey="Precipitacao"
              name="Precipitação"
              stroke="blue"
            />
          </LineChart>
        </ResponsiveContainer>
        <Box pt="32px" position="absolute" right={0} top={0}>
          <Typography
            color="blue"
            variant="subtitle2"
            sx={{ transform: 'rotate(270deg)' }}
          >
            Precipitação (mm)
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
