const initialState = {
  offset: '0',
  limit: '20',
  uf: '',
  city: '',
  culture_name: '',
  cycle: '',
  alert_type: '',
};

export default initialState;
