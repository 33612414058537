export const NAME = 'polygon';

const CONTEXT = 'POLYGONS';

const RESET = `${CONTEXT}::RESET`;

const CHANGE = `${CONTEXT}::CHANGE`;
const MOUNT = `${CONTEXT}::MOUNT`;
const UNMOUNT = `${CONTEXT}::UNMOUNT`;

export const ACTION_TYPES = {
  RESET,
  CHANGE,
  MOUNT,
  UNMOUNT,
};
