import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';

import toaster from '@spot/shared-store/toaster';

import getBlockchainService from '@spot/shared-services/getBlockchain.service';

import currentState from '.';

function toHex(buffer) {
  if (buffer instanceof ArrayBuffer) {
    buffer = new Uint8Array(buffer);
  }
  return Array.prototype.map.call(buffer, byteToHex).join('');
}

function byteToHex(byte) {
  // convert to hex string and pad a zero if necessary
  return ('0' + (byte & 0xff).toString(16)).slice(-2);
}

function sha256(buffer) {
  return window.crypto.subtle.digest({ name: 'SHA-256' }, buffer);
}

async function readFile(file) {
  const blob = file.slice(0);
  const arrayBuffer = await blob.arrayBuffer();
  return arrayBuffer;
}

async function hashFile(file) {
  const arrayBuffer = await readFile(file);
  const hash = await sha256(arrayBuffer);
  const hashHex = toHex(hash);

  return hashHex;
}

const handleServiceGet = function* (action) {
  const { payload } = action;

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const hash = yield call(() => hashFile(payload));

    const [success, result] = yield call(getBlockchainService, hash);

    if (!success) {
      throw result;
    }

    return yield handlers.fetchEnd({
      result,
      payload,
      hash: hash,
    });
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
