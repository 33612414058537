const normalizeWeatherConstant = {
  0: 'Tempo bom',
  1: 'Claro',
  2: 'Parcialmente nublado',
  3: 'Nublado',
  45: 'Névoa',
  48: 'Geada',
  51: 'Garoa leve',
  53: 'Garoa moderada',
  55: 'Garoa densa',
  56: 'Garoa leve e congelante',
  57: 'Garoa gelada densa',
  61: 'Chuva leve',
  63: 'Chuva moderada',
  65: 'Chuva pesada',
  66: 'Chuva leve e gelada',
  67: 'Chuva forte e congelante',
  71: 'Leve queda de neve',
  73: 'Neve moderada',
  75: 'Nevasca',
  77: 'Granizo',
  80: 'Pancada de chuva',
  81: 'Pancada de chuva moderada',
  82: 'Pancada de chuvas pesadas',
  85: 'Neve leve',
  86: 'Neve pesada',
  95: 'Tempestade',
  96: 'Tempestade',
  99: 'Tempestade',
};

export default normalizeWeatherConstant;
