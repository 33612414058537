export const NAME = 'dialogDeleteProfile';

const CONTEXT = 'PROFILE::DIALOGS::DELETE_PROFILE';

const PROFILE_DELETE = `${CONTEXT}::PROFILE::DELETE`;

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const PROFILE = {
  DELETE: PROFILE_DELETE,
};

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

export const ACTION_TYPES = {
  SET,
  RESET,
  PROFILE,
};
