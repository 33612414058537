export const NAME = 'clients';

const CONTEXT = 'PROFILE::CLIENTS';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;
const SERVICE_GET_ID = `${CONTEXT}::SERVICE::GET_ID`;
const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;
const SERVICE_PATCH = `${CONTEXT}::SERVICE::PATCH`;

const UPDATE_FILTERS = `${CONTEXT}::UPDATE::FILTERS`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_END = `${CONTEXT}::FETCH::END`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_FILTERS = `${CONTEXT}::RESET::FILTERS`;

const SERVICE = {
  GET: SERVICE_GET,
  GET_ID: SERVICE_GET_ID,
  POST: SERVICE_POST,
  PATCH: SERVICE_PATCH,
};

const RESET = {
  STATE: RESET_STATE,
  FILTERS: RESET_FILTERS,
};

const FETCH = {
  START: FETCH_START,
  END: FETCH_END,
  ERROR: FETCH_ERROR,
};

const UPDATE = {
  FILTERS: UPDATE_FILTERS,
};

export const ACTION_TYPES = {
  RESET,
  FETCH,
  UPDATE,
  SERVICE,
};
