import reducer from './plan.reducer';
import * as constant from './plan.constant';
import * as action from './plan.action';
import * as selector from './plan.selector';
import saga from './plan.saga';
import initialState from './plan.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
