const ROUTES = {
  ROOT: '/blockchain',
};

const BLOCKCHAIN_ROUTES = {
  ROOT: ROUTES.ROOT,
};

export const NAMESPACE = ROUTES.ROOT;

export default BLOCKCHAIN_ROUTES;
