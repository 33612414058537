import { combineReducers } from 'redux';

import drawers from './dialogs';
import user from './currentUser';
import clients from './clients';
import invoices from './invoices';
import plan from './plan';
import datasource from './datasource';
import config from './config';
import accounts from './accounts';
import companies from './companies';
import profiles from './profiles';
import cooperatives from './cooperatives';
import authenticate from './authenticate';

const reducer = combineReducers({
  [profiles.name]: profiles.reducer,
  [config.name]: config.reducer,
  [datasource.name]: datasource.reducer,
  [cooperatives.name]: cooperatives.reducer,
  [plan.name]: plan.reducer,
  [invoices.name]: invoices.reducer,
  [clients.name]: clients.reducer,
  [companies.name]: companies.reducer,
  [user.name]: user.reducer,
  [drawers.name]: drawers.reducer,
  [accounts.name]: accounts.reducer,
  [authenticate.name]: authenticate.reducer,
});

export default reducer;
